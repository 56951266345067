import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import ModalDialog from "views/Widgets/Common/ModalDialog";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import api from "utils/API";
import apiform from "utils/APIForm";
import TableExamData from "./TableExamData";
import DialogCancelRequest from "./DialogCancelRequest";
import MedicalTableModify from "../../InsumosYMedicamentos/ModifyRequest/MedicalTableModify";
import ResourceTableModify from "../../InsumosYMedicamentos/ModifyRequest/ResourceTableModify";
import {
  getListSuppliesMedicines,
} from 'actions/getListAction';
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: "absolute",
    width: "60%",
    height: "80%",

    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));




const ModalExamData = props => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const {
    openModal,
    closeModal,
    tempRow,
    loadExams,
} = props;


  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [editPageOne, setEditPageOne] = useState(false);
  const [editPageTwo, setEditPageTwo] = useState(false);
  const [indications, setIndications] = useState("");
  const [name, setName] = useState("");
  const [examDate, setExamDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [responsable, setResponsable] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [priority, setPriority] = useState("");
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [examRequest, setExamRequest] = useState(null);
  const [isapreAuthorization, setIsapreAuthorization] = useState(null);
  const [initIsapreAuthorization, setinitIsapreAuthorization] = useState(false);

  const [checkDataMedicalArray, setCheckDataMedicalArray] = useState([]);
  const [addDataMedicalArray, setAddDataMedicalArray] = useState([]);
  const [deleteDataMedicalArray, setDeleteDataMedicalArray] = useState([]);
  const [editDataMedicalArray, setEditDataMedicalArray] = useState([]);

  const [addDataResourceArray, setAddDataResourceArray] = useState([]);
  const [deleteDataResourceArray, setDeleteDataResourceArray] = useState([]);
  const [editDataResourceArray, setEditDataResourceArray] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [dataEquipArray, setDataEquipArray] = useState([]);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getExamDetail = api.get(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/`
    );


    Promise.all([getExamDetail])
      .then(response => {
        console.log("response", response[0].data);
        setIndications(response[0].data.examen.indicaciones);
        setName(response[0].data.examen.id_examen_clinica.nombre);
        setExamDate(
          moment(response[0].data.examen.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setTransfer(response[0].data.examen.traslado);
        setTransferType(response[0].data.examen.tipo_traslado);
        setResponsable(response[0].data.examen.realiza_cmh);
        setPriority(response[0].data.prioridad);
        setIndications(response[0].data.examen.indicaciones);

        let tempSupplies = [];

        let tempMedicalArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempMedicalArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        let tempResourceArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto !== "Medicamentos") {
            tempResourceArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });

        if (response[0].data.examen.id_orden_examen != null) {
          setExamRequest(response[0].data.examen.id_orden_examen);
        }
        if (response[0].data.examen.id_autorizacion_isapre != null) {
          setIsapreAuthorization(
            response[0].data.examen.id_autorizacion_isapre
          );
          setinitIsapreAuthorization(true);
        }

        setDataMedicalArray(tempMedicalArray);
        setDataResourceArray(tempResourceArray);
        setSupplies(tempSupplies);

        setFirstCharge(false);

      })

  };

  const saveEditRequest = () => {
    setOpenAnimation(true);
    let datosEliminar = [];
    deleteDataMedicalArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });
    deleteDataResourceArray.map(row => {
      datosEliminar.push({
        id: row.id_producto_pedido
      });
    });

    let datosAgregar = [];
    addDataMedicalArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });
    addDataResourceArray.map(row => {
      datosAgregar.push({
        id_insumo_medicamento: row.id,
        cantidad: row.cantidad
      });
    });

    let datosEditar = [];
    editDataMedicalArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });
    editDataResourceArray.map(row => {
      datosEditar.push({
        id: row.id_producto_pedido,
        cantidad: row.cantidad
      });
    });

    const send = {};
    if (datosEliminar.length > 0) {
      send["datos_eliminar"] = datosEliminar;
    }
    if (datosAgregar.length > 0) {
      send["datos_agregar"] = datosAgregar;
    }
    if (datosEditar.length > 0) {
      send["datos_modificar"] = datosEditar;
    }

    let sendPatch = {
      indicaciones: indications,
      realiza_cmh: responsable,
      fecha_hora_examen: moment(examDate).format("YYYY-MM-DDTHH:mm:ss"),
      traslado: transfer,
      prioridad: priority
    };
    if (transfer) {
      sendPatch["tipo_traslado"] = transferType;
    }
    console.log("sendPatch", sendPatch);
    console.log("send", send);
    api
      .patch(
        `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/`,
        sendPatch
      )
      .then(response => {
        console.log("response", response);
        api
          .put(
            `api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/${tempRow.id_solicitud_paciente.id}/insumos/modificar/`,
            send
          )
          .then(response => {
            console.log("response", response);
            setOpenAnimation(false);
          })

      })

  };

  const aceptRequest = () => {
    setOpenAnimation(true);
    const formData = new FormData();
    formData.append("archivo", isapreAuthorization[0]);
    formData.append("tipo_documento", "AutorizacionExamenesIsapre");
    formData.append("nombre_archivo", isapreAuthorization[0].name);
    formData.append("id_paciente",tempRow.id_solicitud_paciente.id_paciente_id);
    formData.append("id_solicitud_paciente", parseInt(tempRow.id_solicitud_paciente.id));
  
    const requestFile = apiform.post(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/documentos/autorizacion_examen/subir/`,
      formData
    )
    const requestExam = api.patch(`/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/${tempRow.id_solicitud_paciente.id}/insumos/enviar/`)
    
    Promise.all([requestExam, requestFile]).then(response => {
      window.location.href = "/admin/clinicRequest/";
      })

  };


  const handleResponsable = () => {
    setResponsable(!responsable);
  };

  const handlePriority = (event) => {
    setPriority(event.target.value);
  };

  const handleTransfer = () => {
    setTransfer(!transfer);
  };

  const handleTransferType = (event) => {
    setTransferType(event.target.value);
  };

  const handleExamDate = (date) => {
    setExamDate(date);
  };

  const _content_ = <div>
  {editPageOne ? (
    <Grid container justify="center">
      <Grid
        item
        container
        xs={12}
        style={{ marginTop: "1em" }}
        justify="center"
      >
        <Grid item xs={1} />
        <Grid item container xs={10}>
          <Grid item container xs={12} justify="space-between">
            <Grid item container xs={5} justify="center">
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  ¿Quién realiza el análisis?
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={responsable}
                  onChange={handleResponsable}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="CMH"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="Laboratorio Externo"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item container xs={5} justify="center">
              <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <Grid container justify="center">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD-MM-YYYY"
                    margin="normal"
                    id="date-picker-inline1"
                    label="Fecha del examen"
                    minDate={moment()}
                    minDateMessage={"La fecha del examen no puede ser anterior al día de hoy"}
                    cancelLabel={"Cancelar"}
                    okLabel={"Aceptar"}
                    value={examDate}
                    onChange={handleExamDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <Grid container justify="center">
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Hora del examen"
                    value={examDate}
                    onChange={handleExamDate}
                    KeyboardButtonProps={{
                      "aria-label": "change time"
                    }}
                    cancelLabel={"Cancelar"}
                    okLabel={"Aceptar"}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item container xs={12} justify="center">
        {transfer ? (
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item container xs={4} justify="center">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Prioridad
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={priority}
                  onChange={handlePriority}
                  label="Prioridad"
                >
                  <MenuItem value={"Baja"}>Baja</MenuItem>
                  <MenuItem value={"Media"}>Media</MenuItem>
                  <MenuItem value={"Alta"}>Alta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={4} justify="center">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label-1">
                  Traslado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label-1"
                  id="demo-simple-select-outlined-1"
                  value={transfer}
                  onChange={handleTransfer}
                  label="Traslado"
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={4} justify="center">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label-2">
                  Tipo de traslado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label-2"
                  id="demo-simple-select-outlined-2"
                  value={transferType}
                  onChange={handleTransferType}
                  label="Tipo de traslado"
                >
                  <MenuItem value={"Auto"}>Auto</MenuItem>
                  <MenuItem value={"Basico"}>Básico</MenuItem>
                  <MenuItem value={"Medio"}>Medio</MenuItem>
                  <MenuItem value={"Avanzado"}>Avanzado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item container xs={6} justify="center">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Prioridad
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={priority}
                  onChange={handlePriority}
                  label="Prioridad"
                >
                  <MenuItem value={"Baja"}>Baja</MenuItem>
                  <MenuItem value={"Media"}>Media</MenuItem>
                  <MenuItem value={"Alta"}>Alta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={6} justify="center">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Traslado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={transfer}
                  onChange={handleTransfer}
                  label="Prioridad"
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ marginTop: "2em" }}
      >
        <Grid item xs={1} />
        <Grid item container xs={10} justify="center">
          <Grid item container xs={12} justify="center">
            <TextField
              fullWidth
              multiline
              rows={4}
              id="outlined-basic2"
              label="Indicaciones"
              variant="outlined"
              value={indications}
              onChange={e => setIndications(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid item container xs={12} justify="center">
        <MedicalTableModify
          dataMedicalArray={dataMedicalArray}
          setDataMedicalArray={setDataMedicalArray}
          medicalResourceData={medicalResourceData}
          listMedical={listMedical.filter(
            list =>
              dataMedicalArray.every(data => data.id !== list.value) &&
              addDataMedicalArray.every(data => data.id !== list.value)
          )}
          setPageRefresh={setPageRefresh}
          checkDataMedicalArray={checkDataMedicalArray}
          setCheckDataMedicalArray={setCheckDataMedicalArray}
          addDataMedicalArray={addDataMedicalArray}
          setAddDataMedicalArray={setAddDataMedicalArray}
          deleteDataMedicalArray={deleteDataMedicalArray}
          setDeleteDataMedicalArray={setDeleteDataMedicalArray}
          editDataMedicalArray={editDataMedicalArray}
          setEditDataMedicalArray={setEditDataMedicalArray}
        />
      </Grid>
      <Grid item container xs={12} justify="center">
        <ResourceTableModify
          dataResourceArray={dataResourceArray}
          setDataResourceArray={setDataResourceArray}
          medicalResourceData={medicalResourceData}
          //listResource={listResource}
          listResource={listResource.filter(
            list =>
              dataResourceArray.every(data => data.id !== list.value) &&
              addDataResourceArray.every(data => data.id !== list.value)
          )}
          setPageRefresh={setPageRefresh}
          addDataResourceArray={addDataResourceArray}
          setAddDataResourceArray={setAddDataResourceArray}
          deleteDataResourceArray={deleteDataResourceArray}
          setDeleteDataResourceArray={setDeleteDataResourceArray}
          editDataResourceArray={editDataResourceArray}
          setEditDataResourceArray={setEditDataResourceArray}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Grid container justify="center">
        <Grid item container xs={12} justify="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Nombre examen:
              <span style={{ fontWeight: "bold" }}>{name}</span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Fecha examen:
              <span style={{ fontWeight: "bold" }}>
                {moment(examDate).format("YYYY-MM-DD")}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item container xs={12} justify="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Análisis:
              <span style={{ fontWeight: "bold" }}>
                {responsable ? "CMH" : "Laboratorio Externo"}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Hora examen:
              <span style={{ fontWeight: "bold" }}>
                {moment(examDate).format("HH:mm")}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item container xs={12} justify="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Traslado:
              <span style={{ fontWeight: "bold" }}>
                {transfer ? transferType : "Sin traslado"}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
              Prioridad:
              <span style={{ fontWeight: "bold" }}>{priority}</span>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item container xs={12} justify="center" spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="body2" gutterBottom>
              Indicaciones:
              <span style={{ fontWeight: "bold" }}>{indications}</span>
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="center">
        <TableExamData supplies={supplies} />
      </Grid>
      {initIsapreAuthorization ? 
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginBottom: "4px" }}
        >
          {isapreAuthorization != null
            ? isapreAuthorization[0].name
            : null}
        </Grid> :
      <Grid
      item
      container
      xs={12}
      style={{ margin: "10px 0" }}
      justify="center"
    >
      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ marginBottom: "4px" }}
      >
        {isapreAuthorization != null
          ? isapreAuthorization[0].name
          : null}
      </Grid>
      <input
        id="contained-button-file"
        //multiple
        type="file"
        onChange={e => setIsapreAuthorization(e.target.files)}
        hidden
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ textTransform: "none", marginRight: "3px" }}
        >
          Adjuntar autorización isapre
        </Button>
      </label>
    </Grid>}
    </Grid>
  )}
  </div>

  const _actions_ = <>
    {editPageOne ? (
      loadExams ? null : (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{
              textTransform: "none"
            }}
            onClick={() => {

              setFirstCharge(true);
              setEditPageOne(false);
              setEditPageTwo(false);
            }}
          >
            Atrás
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#43a047",
              textTransform: "none",
              color: "#fff"
            }}
            onClick={() => {
              saveEditRequest();
            }}
          >
            Guardar cambios
          </Button>
        </>
      )
    ) : editPageTwo ? (
      <>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none"
          }}
          onClick={() => {
            setEditPageOne(false);
            setEditPageTwo(false);
            //editRequest(false);
          }}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#43a047",
            textTransform: "none",
            color: "#fff"
          }}
          onClick={() => {
            saveEditRequest();
          }}
        >
          Guardar cambios
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none"
          }}
          onClick={() => {
            setEditPageOne(true);
            setEditPageTwo(false);
          }}
        >
          Página anterior
        </Button>
      </>
    ) : isapreAuthorization != null && examRequest != null ? (
      <>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#43a047",
            textTransform: "none",
            color: "#fff"
          }}
          onClick={() => {
            aceptRequest();
          }}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none"
          }}
          onClick={closeModal}
        >
          Atrás
        </Button>
      </>
    ) : (
      <>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#43a047",
            textTransform: "none",
            color: "#fff"
          }}
          onClick={() => {
            setEditPageOne(true);
            setEditPageTwo(false);
          }}
        >
          Editar
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            textTransform: "none"
          }}
          onClick={closeModal}
        >
          Atrás
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{
            textTransform: "none"
          }}
          onClick={() => {
            setOpenDialogCancelRequest(true);
          }}
        >
          Cancelar envío
        </Button>
        {examRequest != null ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#43a047",
              textTransform: "none",
              color: "#fff"
            }}
            onClick={() => {
              aceptRequest();
            }}
          >
            Aceptar
          </Button>
        ) : null}
      </>
    )}
  </>


  return (
    <>
      <ModalDialog
          open={openModal}
          onClose={closeModal}
          title={`Datos del examen a solicitar 2`}
          _content_={_content_}
          _actions_ = {_actions_}
      />
      {openDialogCancelRequest && (
        <DialogCancelRequest
          openDialog={openDialogCancelRequest}
          closeDialog={() => setOpenDialogCancelRequest(false)}
          closeMainModal={closeModal}
          tempRow={tempRow}
        />
      )}
    </>
  );
};

export default ModalExamData;

ModalExamData.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  row: PropTypes.object,
  loadExams: PropTypes.bool,
  tempRow: PropTypes.object
};
