const initialState = {
    ammountValuePackage: 0,
    ammountResourcePackage: 0,
    ammountMedicinePackage: 0,
    amountProfesionalPAckage: 0,
    openBackdrop: false,
    enablePatientAddress: false,
    UTM: 0,
    UF: 0,
    UF2: 0,
    TUF: 0,
    TUF2: 0,
    minSalary: 0,
    listSecondCatechoryTax: [],
    TITULO_DIALOGO_DASHBOARD: "",
    TITULO_DIALOGO_NAVBAR: "",
    MENSAJE_DIALOGO_DASHBOARD: "",
    MENSAJE_DIALOGO_NAVBAR: "",
    ABRIR_DIALOGO_DASHBOARD: false,
    ABRIR_DIALOGO_NAVBAR: false,
    DESHABILITAR_ASISTENCIA_ENTRADA: true,
    DESHABILITAR_ASISTENCIA_SALIDA: true,
    ABRIR_DIALOGO_BIENVENIDA_DASHBOARD: false,
    ASISTENCIA_TIPO: "",
    ASISTENCIA_URL: "",
    ABRIR_DIALOGO_RECORDATORIO: false,
    FECHA_DIALOGO_RECORDATORIO: new Date().getTime(),
    chargeMedicine: false,
    chargeEquip: false,
    generalMessage: "",
    typeMessage: "",
    titleMessage: "",
    actionGlobalMessage: null,
    nameActionGlobalMessage: "",
    messageAlert: "",
    messageType: "",
    messagePlace: "",
    quotationDiscount: "",
    typeQuotationDiscount: "",
    TotalValuePackage: 0,
    TotalValueMonthConsume:0,
    TotalValuePackageConsume:0,
    ValueUpdate:0,
    patientPackage: null,
    dictDiscountPaquetePatient: {},
    patientClinicOrder: {},
    versionAPP: "1.0.22" //TODO: Cambiar por la version actual de la aplicacion
};

const currentGlobalVar = (state = initialState, action) => {
    switch (action.type) {
        case "VALOR_INSUMOS_MEDICAMENTOS_PAQUETE":
            return {
                ...state,
                ammountValuePackage: action.payload,
            }
        case "VALOR_INSUMOS_PAQUETE":
            return {
                ...state,
                ammountResourcePackage: action.payload,
            }
        case "VALOR_MEDICAMENTOS_PAQUETE":
            return {
                ...state,
                ammountMedicinePackage: action.payload,
            }
        case "VALOR_PROFESIONALES_PAQUETE":
            return {
                ...state,
                amountProfesionalPAckage: action.payload,
            }
        case "SUMAR_INSUMOS_MEDICAMENTOS_PAQUETE":
            return {
                ...state,
                ammountValuePackage: state.ammountValuePackage + action.payload,
            }
        case "RESTAR_INSUMOS_MEDICAMENTOS_PAQUETE":
            return {
                ...state,
                ammountValuePackage: state.ammountValuePackage - action.payload,
            }
        case "BLOQUEAR_PANTALLA":
            return {
                ...state,
                openBackdrop: action.payload,
            }
        case "HABILITACION_PACIENTE_DISPONIBLE":
            return {
                ...state,
                enablePatientAddress: action.payload,
            }
        case "UTM_VALOR":
            return {
                ...state,
                UTM: action.payload,
            }
        case "UF_VALOR":
            return {
                ...state,
                UF: action.payload,
            }
        case "UF_SEGURO_VALOR":
            return {
                ...state,
                UF2: action.payload,
            }
        case "TUF_VALOR":
            return {
                ...state,
                TUF: action.payload,
            }
        case "TUF_SEGURO_VALOR":
            return {
                ...state,
                TUF2: action.payload,
            }
        case "SALARIO_MINIMO_SEGURO_VALOR":
            return {
                ...state,
                minSalary: action.payload,
            }
        case "IMPUESTO_SEGUNDA_CATEGORIA":
            return {
                ...state,
                listQuotationAFP: action.payload,
            }
        case "TITULO_DIALOGO_DASHBOARD":
            return {
                ...state,
                TITULO_DIALOGO_DASHBOARD: action.payload,
            }
        case "TITULO_DIALOGO_NAVBAR":
            return {
                ...state,
                TITULO_DIALOGO_NAVBAR: action.payload,
            }
        case "MENSAJE_DIALOGO_DASHBOARD":
            return {
                ...state,
                MENSAJE_DIALOGO_DASHBOARD: action.payload,
            }
        case "MENSAJE_DIALOGO_NAVBAR":
            return {
                ...state,
                MENSAJE_DIALOGO_NAVBAR: action.payload,
            }
        case "ABRIR_DIALOGO_DASHBOARD":
            return {
                ...state,
                ABRIR_DIALOGO_DASHBOARD: action.payload,
            }
        case "ABRIR_DIALOGO_NAVBAR":
            return {
                ...state,
                ABRIR_DIALOGO_NAVBAR: action.payload,
            }
        case "DESHABILITAR_ASISTENCIA_ENTRADA":
            return {
                ...state,
                DESHABILITAR_ASISTENCIA_ENTRADA: action.payload,
            }
        case "DESHABILITAR_ASISTENCIA_SALIDA":
            return {
                ...state,
                DESHABILITAR_ASISTENCIA_SALIDA: action.payload,
            }
        case "ABRIR_DIALOGO_BIENVENIDA_DASHBOARD":
            return {
                ...state,
                ABRIR_DIALOGO_BIENVENIDA_DASHBOARD: action.payload,
            }
        case "ASISTENCIA_TIPO":
            return {
                ...state,
                ASISTENCIA_TIPO: action.payload,
            }
        case "ASISTENCIA_URL":
            return {
                ...state,
                ASISTENCIA_URL: action.payload,
            }
        case "ABRIR_DIALOGO_RECORDATORIO":
            return {
                ...state,
                ABRIR_DIALOGO_RECORDATORIO: action.payload,
            }
        case "FECHA_DIALOGO_RECORDATORIO":
            return {
                ...state,
                FECHA_DIALOGO_RECORDATORIO: action.payload,
            }
        case "TOTAL_RECURSOS_MEDICOS":
            return {
                ...state,
                medicalResourseTotal: action.payload,
            }
        case "CARGANDO_MEDICAMENTOS":
            return {
                ...state,
                chargeMedicine: action.payload,
            }
        case "CARGANDO_EQUIPOS":
            return {
                ...state,
                chargeEquip: action.payload,
            }
        case "MENSAJE_GENERAL":
            return {
                ...state,
                generalMessage: action.payload,
            }
        case "TIPO_MENSAJE":
            return {
                ...state,
                typeMessage: action.payload,
            }
        case "MENSAJE_TITULO":
            return {
                ...state,
                titleMessage: action.payload,
            }
        case "BOTON_ACCION_MENSAJE":
            return {
                ...state,
                actionGlobalMessage: action.payload,
            }
        case "NOMBRE_BOTON_ACCION_MENSAJE":
            return {
                ...state,
                nameActionGlobalMessage: action.payload,
            }
        case "MENSAJE_ALERTA":
            return {
                ...state,
                messageAlert: action.payload,
            }
        case "TIPO_ALERTA":
            return {
                ...state,
                messageType: action.payload,
            }
        case "POSICION_ALERTA":
            return {
                ...state,
                messagePlace: action.payload,
            }
        case "TOTAL_VALOR_PAQUETE":
            return {
                ...state,
                TotalValuePackage: action.payload,
            }
        case "VALOR_CONSUMIDO_MES":
            return {
                ...state,
                TotalValueMonthConsume: action.payload,
            }
        case "VALOR_CONSUMIDO_PAQUETE":
            return {
                ...state,
                TotalValuePackageConsume: action.payload,
            }
        case "VALOR_ACTUALIZADO":
            return {
                ...state,
                ValueUpdate: action.payload,
            }
        case "DESCUENTO_COTIZACION":
            return {
                ...state,
                quotationDiscount: action.payload,
            }
        case "TIPO_DESCUENTO_COTIZACION":
            return {
                ...state,
                typeQuotationDiscount: action.payload,
            }
        case "PAQUETE_PACIENTE":
            return {
                ...state,
                patientPackage: action.payload,
            }
        case "DICCIONARIO_DESCUENTO_PAQUETE_PACIENTE":
            return {
                ...state,
                dictDiscountPaquetePatient: action.payload,
            }
        case "PACIENTE_ORDEN_CLINICA":
            return {
                ...state,
                patientClinicOrder: action.payload,
            }
        default:
            return state
    }
};

export default currentGlobalVar;