import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import api from "utils/API";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textTransform: "none",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ModalBalance = (props) => {
  const { openModal, closeModal, tempRowModal, handleChange } = props;
  const [changes, setChanges] = useState(true);
  const [value, setValue] = useState(0);
  const [openProcess, setOpenProcess] = useState(true);
  const [idTipoMovimiento, setIdTipoMovimiento] = useState(false);

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const getBalanceType = api.get("api/tipobalancehidrico/");

    Promise.all([getBalanceType])
      .then((response) => {
        setIdTipoMovimiento(
          response[0].data.filter(
            (filtered) =>
              filtered.tipo_io === tempRowModal.comentarios &&
              filtered.nombre === tempRowModal.descripcion
          )
        );
        setOpenProcess(false);
      })

  };

  const addSignoVital = () => {
    setOpenProcess(true);
    const formData = new FormData();
    if (tempRowModal.comentarios === "Egresos") {
      formData.append("tipo_io", "Egresos");
    }
    if (tempRowModal.comentarios === "Ingresos") {
      formData.append("tipo_io", "Ingresos");
    }
    formData.append("fecha_registro", moment().format());
    formData.append("cantidad", value);
    formData.append("id_tipo_movimiento", idTipoMovimiento[0].id);
    formData.append("id_ficha", tempRowModal.id_ficha);

    if (
      tempRowModal.id_solicitud_paciente &&
      tempRowModal.tipo_tarea.includes("Descomp")
    ) {
      const body = {
        comentario: `Balance agregado del tipo: ${tempRowModal.comentarios}, valor: ${value}`,
        manual: true,
        tipo_comentario: "RespuestaCompesacion",
        id_descompensacion: null,
        id_usuario: localStorage.getItem("user_id"),
      };
      api
        .post(
          `api/paciente/comentariosdescompensacion/solicitud/${tempRowModal.id_solicitud_paciente}/`,
          body
        )
        .then(() => {})

    }

    apiform
      .post("api/balancehidrico/", formData)
      .then(() => {
        handleChange(true, tempRowModal);
        closeModal();
      })

  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      titleStyle={{ textAlign: "center" }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        {`Tipo de ${tempRowModal.comentarios}` }
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} container justify="center">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={`${tempRowModal.descripcion} ml`}
              variant="outlined"
              value={value}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => {
                if (/^[0-9.\b]+$/.test(e.target.value)) {
                  setValue(parseFloat(e.target.value));
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        {openProcess ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={addSignoVital}
            style={{ textTransform: "none" }}
          >
            Agregar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalBalance;

ModalBalance.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRowModal: PropTypes.object,
  handleChange: PropTypes.func,
  tempIndex: PropTypes.number,
};
