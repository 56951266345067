import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TableOthers = props => {
  const classes = useStyles();
  const { others, setOthers, setPageRefresh, getSpecialties } = props;
  const [editRow, setEditRow] = useState(-1);
  
  const setNameSpeciality = id => {
    return getSpecialties
      .filter(specialty => specialty.id === id)
      .map(specialty => specialty.nombre_corto);
  };

  const updateAllRow = (tempRow, event, column) => {
    return tempRow.map((row) => {
      const auxRow = {...row}
      auxRow[column] = event;
      return auxRow
    } )
  }


  const handleStartDateChange = (event, index) => {
    let tempRow = others;
    tempRow[index].startDate = moment(event).format("YYYY-MM-DD[T]HH:mm:ss");
    if (index === 0){
      tempRow = updateAllRow(tempRow,  moment(event).format("YYYY-MM-DD[T]HH:mm:ss"), "startDate")
    }
    setOthers(tempRow);
    setPageRefresh(true);
  };

  const handleEndDateChange = (event, index) => {
    let tempRow = others;
    tempRow[index].endDate = moment(event).format("YYYY-MM-DD[T]HH:mm:ss");
    if (index === 0){
      tempRow = updateAllRow(tempRow,  moment(event).format("YYYY-MM-DD[T]HH:mm:ss"), "endDate")
    }
    setOthers(tempRow);
    setPageRefresh(true);
  };

  const handleChange = (event, index) => {
    let tempRow = others;
    tempRow[index].select = event;
    setOthers(tempRow);
    setPageRefresh(true);
  };

  const handleSelect = (event, index) => {
    let tempRow = others;
    tempRow[index].speciality = event.target.value;
    if (index === 0){
      tempRow = updateAllRow(tempRow, event.target.value, "speciality")
    }
    setOthers(tempRow);
    setPageRefresh(true);
  };

  const handleEdit = (e, name, index) => {
    let tempRow = others;
    tempRow[index][name] = e.target.value;
    setOthers(tempRow);
    setPageRefresh(true);
  };

  const startEditing = index => {
    setEditRow(index);
  };

  const stopEditing = () => {
    setEditRow(-1);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Editar</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Item</TableCell>
              <TableCell align="center">Fecha inicio</TableCell>
              <TableCell align="center">Fecha fin</TableCell>
              <TableCell align="center">Observación</TableCell>
              <TableCell align="center">Especialista</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {others.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    {editRow === index ? (
                      <CheckIcon onClick={() => stopEditing(index)} />
                    ) : (
                      <EditIcon onClick={() => startEditing(index)} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.select}
                      onChange={e => {
                        handleChange(e.target.checked, index);
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">{row.item}</TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline1"
                            value={row.startDate}
                            onChange={e => handleStartDateChange(e, index)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    ) : (
                      moment(row.startDate).format("YYYY-MM-DD HH:mm")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline2"
                            value={row.endDate}
                            onChange={e => handleEndDateChange(e, index)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    ) : (
                      moment(row.endDate).format("YYYY-MM-DD HH:mm")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <TextField
                        id="standard-basic"
                        value={row.observation}
                        onChange={e => handleEdit(e, "observation", index)}
                      />
                    ) : (
                      row.observation
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={row.speciality}
                          onChange={e => handleSelect(e, index)}
                        >
                          {getSpecialties.map(specialty => (
                            <MenuItem key={specialty.id} value={specialty.id}>
                              {specialty.nombre_corto}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      setNameSpeciality(row.speciality)
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableOthers;

TableOthers.propTypes = {
  others: PropTypes.array,
  setOthers: PropTypes.func,
  setPageRefresh: PropTypes.func,
  getSpecialties: PropTypes.array
};
