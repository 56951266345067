import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import apiform from "utils/APIForm";
import DialogCancelRequest from "./DialogCancelRequest";
import DialogSuccess from "./DialogSuccess";
import api from "utils/API";
import TableExamData from "./TableExamData";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const ModalUploadAuthorization = props => {
  const { openModal, closeModal, tempRow } = props;

  const [openAnimation, setOpenAnimation] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [fileIsapreAuthorization, setFileIsapreAuthorization] = useState(null);
  const [openDialogCancelRequest, setOpenDialogCancelRequest] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);

  const [indications, setIndications] = useState("");
  const [name, setName] = useState("");
  const [examDate, setExamDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss")
  );
  const [responsable, setResponsable] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [transferType, setTransferType] = useState("");
  const [priority, setPriority] = useState("");
  const [dataMedicalArray, setDataMedicalArray] = useState([]);
  const [dataResourceArray, setDataResourceArray] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [examRequest, setExamRequest] = useState(null);
  const [isapreAuthorization, setIsapreAuthorization] = useState(null);
  const [uploadAuthorization, setUploadAuthorization] = useState(null);


  useEffect(() => {
    if (firstCharge) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setFirstCharge(false);
    const getExamDetail = api.get(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/`
    );


    Promise.all([getExamDetail])
      .then(response => {
        console.log("response", response[0].data);
        setIndications(response[0].data.examen.indicaciones);
        setName(response[0].data.examen.id_examen_clinica.nombre);
        setExamDate(
          moment(response[0].data.examen.fecha_hora_examen).format(
            "YYYY-MM-DDTHH:mm:ss"
          )
        );
        setTransfer(response[0].data.examen.traslado);
        setTransferType(response[0].data.examen.tipo_traslado);
        setResponsable(response[0].data.examen.realiza_cmh);
        setPriority(response[0].data.prioridad);
        setIndications(response[0].data.examen.indicaciones);

        let tempSupplies = [];

        let tempMedicalArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto === "Medicamentos") {
            tempMedicalArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });
        let tempResourceArray = [];
        response[0].data.productos_pedidos.map(row => {
          if (row.id_insumo_medicamento__grupo_producto !== "Medicamentos") {
            tempResourceArray.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              id_producto_pedido: row.id
            });

            tempSupplies.push({
              id: row.id_insumo_medicamento_id,
              item: row.id_insumo_medicamento__descripcion_producto,
              sku: row.id_insumo_medicamento__SKU,
              cantidad: row.cantidad,
              tipo: row.id_insumo_medicamento__grupo_producto
            });
          }
        });

        if (response[0].data.examen.id_orden_examen != null) {
          setExamRequest(response[0].data.examen.id_orden_examen);
        }
        if (response[0].data.examen.id_autorizacion_isapre != null) {
          setUploadAuthorization(false);
          setIsapreAuthorization(
            response[0].data.examen.id_autorizacion_isapre
          );
        } else {
          setUploadAuthorization(true);
        }

        setDataMedicalArray(tempMedicalArray);
        setDataResourceArray(tempResourceArray);
        setSupplies(tempSupplies);

        setFirstCharge(false);

      })

  };

  const saveIsapreAuthorization = () => {
    setOpenAnimation(true);
    const formData = new FormData();
    formData.append("archivo", fileIsapreAuthorization[0]);
    formData.append("tipo_documento", "AutorizacionExamenesIsapre");
    formData.append("nombre_archivo", fileIsapreAuthorization[0].name);
    formData.append("id_paciente",tempRow.id_solicitud_paciente.id_paciente_id);
    formData.append("id_solicitud_paciente", parseInt(tempRow.id_solicitud_paciente.id));
    apiform.post(
      `/api/paciente/${tempRow.id_solicitud_paciente.id_paciente_id}/solicitudes/examenes/${tempRow.id_solicitud_paciente.id}/documentos/autorizacion_examen/subir/`,
      formData
    ).then(responseFile => {
      console.log("response file", responseFile);
      setOpenDialogSuccess(true);
      setOpenAnimation(false);
    })

  };

  const _content_ = <Grid
      item
      container
      xs={12}
      justify="center"
    >
        <Grid>
        <Grid container justify="center">
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Nombre examen:
                <span style={{ fontWeight: "bold" }}>{name}</span>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Fecha examen:
                <span style={{ fontWeight: "bold" }}>
                  {moment(examDate).format("YYYY-MM-DD")}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Análisis:
                <span style={{ fontWeight: "bold" }}>
                  {responsable ? "CMH" : "Laboratorio Externo"}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Hora examen:
                <span style={{ fontWeight: "bold" }}>
                  {moment(examDate).format("HH:mm")}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Traslado:
                <span style={{ fontWeight: "bold" }}>
                  {transfer ? transferType : "Sin traslado"}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" gutterBottom>
                Prioridad:
                <span style={{ fontWeight: "bold" }}>{priority}</span>
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid item container xs={12} justify="center" spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Typography variant="body2" gutterBottom>
                Indicaciones:
                <span style={{ fontWeight: "bold" }}>{indications}</span>
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center">
          <TableExamData supplies={supplies} />
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={6}
        style={{ margin: "10px 0" }}
        justify="center"
      >
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginBottom: "4px" }}
        >
          {fileIsapreAuthorization != null
            ? fileIsapreAuthorization[0].name
            : null}
        </Grid>
        <input
          id="contained-button-file"
          //multiple
          type="file"
          onChange={e => setFileIsapreAuthorization(e.target.files)}
          hidden
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ textTransform: "none", marginRight: "3px" }}
          >
            Adjuntar autorización isapre
          </Button>
        </label>
      </Grid>
      {openDialogCancelRequest && (
          <DialogCancelRequest
            openDialog={openDialogCancelRequest}
            closeDialog={() => setOpenDialogCancelRequest(false)}
            closeMainModal={closeModal}
          />
        )}
        {openDialogSuccess && (
          <DialogSuccess
            openDialog={openDialogSuccess}
            closeDialog={() => {
              window.location.href = "/admin/clinicRequest/";
            }}
          />
        )}
    </Grid>

  const _actions_ = <>
      {fileIsapreAuthorization != null ? (
      <Button
        variant="contained"
        style={{
          backgroundColor: "#43a047",
          textTransform: "none",
          color: "#fff"
        }}
        onClick={() => {
          saveIsapreAuthorization();
          //editRequest(true);
        }}
      >
        Subir autorización
      </Button>
    ) : (
      <Button
        disabled
        variant="contained"
        color="primary"
        style={{
          textTransform: "none"
        }}
        onClick={() => {
          saveIsapreAuthorization();
          //editRequest(true);
        }}
      >
        Subir autorización
      </Button>
    )}
    <Button
      variant="contained"
      color="secondary"
      style={{
        textTransform: "none"
      }}
      onClick={() => {
        setOpenDialogCancelRequest(true);
      }}
    >
      Cancelar Examen
    </Button>
    <Button
      variant="contained"
      color="primary"
      style={{
        textTransform: "none"
      }}
      onClick={() => {
        //editRequest(false);
      }}
    >
      Atrás
    </Button>
    
  </>

  return (
    <ModalDialog
      open={openModal}
      onClose={closeModal}
      title={`Autorización Isapre`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="md"
  />
  );
};

export default ModalUploadAuthorization;

ModalUploadAuthorization.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRow: PropTypes.object
};
