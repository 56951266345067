import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import {
  Grid,
  Link,
} from "@material-ui/core";
import ModalnewPatient from "components/PatientFile/ModalnewPatient";
import ModalChargePatient from "components/PatientFile/ModalChargePatient";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import moment from "moment-timezone";
import api from "utils/API";
import PatientFile from "./PatientFile";
import {
  getlistForecast,
  getlistPackage,
  getlistPackaging,
  getListSuppliesMedicines,
  getListEquipment,
} from 'actions/getListAction';
import {CustomCardItem} from "utils/componentsStyle"
import apiformxlsx from "utils/APIForm"
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"

import "moment/locale/es";
moment.locale("es");

const PatientFileMenu = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const id_paciente = parseInt(id, 10);
  const [selectPatient, setselectPatient] = useState(null);
  const [openModalNewUser, setOpenModalNewUser] = useState(false);
  const [openModalChargeUser, setopenModalChargeUser] = useState(false);
  const [listPercentage, setlistPercentage] = useState([]);
  const [fileMultiUser, setfileMultiUser] = useState(null)
  const [fileMultiPackage, setfileMultiPackage] = useState(null)
  const [fileMultiExam, setfileMultiExam] = useState(null)
  const [listassigned, setlistassigned] = useState([]);
  const [listProfDecompensation, setlistProfDecompensation] = useState([]);

  const [initialCharge, setinitialCharge] = useState(true);
  const [loading, setloading] = useState(false);
  const [listCoordinators, setlistCoordinators] = useState([]);
  const [chargeColor, setChargeColor] = useState([]);

  const listPeriod = [
    { value: "Horario", label: "Horario" },
    { value: "Diario", label: "Diario" },
    { value: "Semanal", label: "Semanal" },
    { value: "Mensual", label: "Mensual" },
    { value: "Anual", label: "Anual" },
  ];

  const listCharges = useSelector(state => state.currentList.listCharges);
  const listForecast = useSelector(state => state.currentList.listForecast);
  const listPackage = useSelector(state => state.currentList.listPackage);
  const listPackaging = useSelector(state => state.currentList.listPackaging);
  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listEquip = useSelector(state => state.currentList.listEquip);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const chargeEquip = useSelector(state => state.currentGlobalVar.chargeEquip);


  useEffect(() => {
    if (listForecast.length === 0){
      getlistForecast(dispatch);
    }
    if (listPackage.length === 0){
      getlistPackage(dispatch);
    }
    if (listPackaging.length === 0){
      getlistPackaging(dispatch);
    }
    if(listMedical.length === 0 && !chargeMedicine) {
      getListSuppliesMedicines(dispatch, chargeMedicine)
    }
    if(listEquip.length === 0 && !chargeEquip) {
      getListEquipment(dispatch, chargeEquip)
    }
  }, []);


  useEffect(() => {
    if (initialCharge) {
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    if (id_paciente){
      let url = `api/paciente/?paciente=${id_paciente}`;
      api.get(url).then((response) => {
        setselectPatient(response.data[0])
      })
    }
    if (localStorage.getItem("open_solicitud") === '') {
      localStorage.setItem("open_solicitud", "false")
    }

    const requestChargesWorks = api.get("api/paciente/cargos/tareas/");
    const requestCoordinator = api.get("api/cordinadores/pacientes/")
    const requestChargesColor = api.get(`api/cordinadores/listacolores/`);
    const requestProfesionalDecompensation = api.get("api/paciente/descompensacion/obtener/profesionales/");
    Promise.all([
      requestChargesWorks,
      requestCoordinator,
      requestChargesColor,
    ]).then((request) => {
      const auxChargesTemp = request[0].data;

      setlistCoordinators(
        request[1].data.map((row) => {
          return { id: row.id_usuario_id, label: `${row.nombres} ${row.apellido_paterno}`, charge: row.id_cargo__nombre_corto, contact: 'data is missing' }
        })
      );
      setChargeColor(request[2].data)

      setlistassigned([
        {
          label: "Enfermeria",
          options: auxChargesTemp["nursingList"],
        }
      ]);
      setlistPercentage(
        Array.from(Array(11), (e, i) => {
          let value = 50 + i * 5;
          value = value.toString()
          return { value: value, label: value };
        })
      );
      setloading(true);

      if (localStorage.getItem('patientFile') !== '') {
        const patiendFile = JSON.parse(localStorage.getItem('patientFile'))
        setselectPatient(patiendFile);
      }
    });

    Promise.all([
      requestProfesionalDecompensation,
    ]).then((request) => {

      setlistProfDecompensation(request[0].data);

      let tempEquip = [];
      request[0].data.map((row) => {
        tempEquip.push({
          value: row.id,
          label: `(${row.SKU}) ${row.descripcion}`,
        });
      });
    });
  };

  const handleopenModalChargeUser = () => {
    setopenModalChargeUser(true);
    setselectPatient(null);
  };

  const handleMultipleUser = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiUser);
    apiformxlsx.post("api/paciente/cargamasiva/", formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiUser(null)
      })
      .catch(error => {
        setfileMultiUser(null)
        {}
      });
  }

  const handleMultiplePackage = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiPackage);
    apiformxlsx.post("api/paciente/cargamasiva/paquete/", formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiPackage(null)
      })
      .catch(error => {
        setfileMultiPackage(null)
        {}
      });
  }

  const handleMultipleExam = () => {
    const formData = new FormData();
    formData.append("archivo", fileMultiExam);
    apiformxlsx.post("api/cargamasiva/examenes/", formData).then(response => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `resultado_carga_masiva_${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(link);
        link.click();
        setfileMultiPackage(null)
      })
      .catch(error => {
        setfileMultiPackage(null)
        {}
      });
  }

  const handleDownloadBaseFile = () => {
    api.get("api/paciente/descargarbasemasiva/", { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_pacientes.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        {}
      });
  }

  const handleDownloadBaseFilePackagePatient = () => {
    api.get("api/paciente/descargarbasemasiva/paquete/", { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_paquetes_pacientes.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        {}
      });
  }

  const handleDownloadBaseFileExam = () => {
    api.get("api/descargarbasemasiva/examenes/", { responseType: "blob" }).then(response => {
        var blob = new Blob([response.data], { type: response.headers["content-type"] });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "carga_masiva_examenes.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => {
        {}
      });
  }

  const permissionCharge = !["CLITecEnfBas", "CLIKineMot", "CLIKineResp", "CLIFono", "CLIEnfRuta", "CLITerOcu"].includes(localStorage.getItem("tipo_perfil"))


  return (
    <div>
      {selectPatient?.id ? (
        <PatientFile
          handleopenModalChargeUser={handleopenModalChargeUser}
          selectPatient={selectPatient}
          setselectPatient={setselectPatient}
          listPercentage={listPercentage}
          listPeriod={listPeriod}
          listassigned={listassigned}
          listProfDecompensation={listProfDecompensation}
          listCoordinators={listCoordinators}
          chargeColor={chargeColor}
        />
      ) : (
        <GridContainer>
          {loading ? (
            <Grid item container xs={12} direction="row" alignContent="flex-start" justify="center">
              {permissionCharge &&
              <CustomCardItem title={"Nuevo Paciente"}
                content={"Permite crear un paciente nuevo de forma manual."}
                textAction={"Crear Paciente"} action={() => setOpenModalNewUser(true)}
                md={6}
                sm={6}
                xs={12}
              />}
              <CustomCardItem title={"Cargar Ficha Paciente"}
                content={"Permite cargar la ficha clínica de un paciente en específico."}
                textAction={"Cargar Paciente"} action={() => handleopenModalChargeUser()}
                md={6}
                sm={6}
                xs={12}
              />
              {permissionCharge &&
                <CustomCardItem title={"Carga masiva pacientes"}
                  content={"Permite crear o actualizar pacientes en una carga masiva."}
                  textAction={fileMultiUser ? "Carga masiva de pacientes": ""}
                  action={() => handleMultipleUser()}
                  subComponent={ <Link href="#" onClick={() => handleDownloadBaseFile()}>
                    Descargar archivo base
                  </Link>}
                  component={ fileMultiUser ? null : <Button
                    variant='contained'
                    color='primary'
                  >
                  <span className="btn-file">
                    Adjuntar archivo carga masiva pacientes
                  <input
                    type="file"
                    accept="image/xlsx"
                    name="archivo"
                    onChange={e => setfileMultiUser(e.target.files[0])}
                  />
                  </span>
                </Button>}
                 md={6}
                 sm={6}
                 xs={12}
                />
              }
              {permissionCharge &&
                <CustomCardItem title={"Carga masiva paquetes paciente"}
                  content={"Permite actualizar o habilitar los paquetes de los pacientes en una carga masiva."}
                  textAction={fileMultiPackage ? "Carga masiva de pacientes": ""}
                  action={() => handleMultiplePackage()}
                  subComponent={ <Link href="#" onClick={() => handleDownloadBaseFilePackagePatient()}>
                    Descargar archivo base
                  </Link>}
                  component={ fileMultiPackage ? null : <Button
                    variant='contained'
                    color='primary'
                  >
                  <span className="btn-file">
                    Adjuntar archivo carga masiva paquete pacientes
                  <input
                    type="file"
                    accept="image/xlsx"
                    name="archivo"
                    onChange={e => setfileMultiPackage(e.target.files[0])}
                  />
                  </span>
                </Button>}
                 md={6}
                 sm={6}
                 xs={12}
                />
              }
              {permissionCharge &&
                <CustomCardItem title={"Carga masiva examenes"}
                  content={"Permite crear o actualizar los datos de Examenes"}
                  textAction={fileMultiExam ? "Carga masiva de examenes": ""}
                  action={() => handleMultipleExam()}
                  subComponent={ <Link href="#" onClick={() => handleDownloadBaseFileExam()}>
                    Descargar archivo base
                  </Link>}
                  component={ fileMultiExam ? null : <Button
                    variant='contained'
                    color='primary'
                  >
                  <span className="btn-file">
                    Adjuntar archivo carga masiva examenes
                  <input
                    type="file"
                    accept="image/xlsx"
                    name="archivo"
                    onChange={e => setfileMultiExam(e.target.files[0])}
                  />
                  </span>
                </Button>}
                 md={6}
                 sm={6}
                 xs={12}
                />
              }
          </Grid>
          ) : (
            <LoadInfo title={"Cargando información ..."} />
          )}
        </GridContainer>
      )}
      {openModalNewUser && (
        <ModalnewPatient
          open={openModalNewUser}
          handleClose={() => setOpenModalNewUser(false)}
          setselectPatient={setselectPatient}
        />
      )}
      {openModalChargeUser && (
        <ModalChargePatient
          open={openModalChargeUser}
          handleClose={() => setopenModalChargeUser(false)}
          setselectPatient={setselectPatient}
        />
      )}
    </div>
  );
};

export default PatientFileMenu;
