import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Button as ButtonMUI,
  Grid
} from "@material-ui/core";
import NavPills from "components/NavPills/NavPills.js";
import WorkTable from "views/Widgets/FichaPaciente/WorkTable";

export default function WorkTabs({
  dataArray, 
  setdata, 
  openModal, 
  setmodalNewWorkPermanent,
  setselectWorkRow,
  setselectRemoveWorkRow,
  seteditWork,
  setendVisit,
  setselectUpdateState,
  setsizePatient,
  settemperature,
  temperature,
  setweight,
  setheartRate,
  setbreathingFrequency,
  setoxygenSaturation,
  setbloodPressureSystolic,
  setbloodPressureDiastolic,
  setbloodPressureMean,
  setpain,
  setallergy,
  handleDiagnosis,
  setUpdatePage,
  width,
}) {

  const type_user = localStorage.getItem("tipo_perfil");

    return (
      <Grid container>
        {type_user !== "CLITecEnfBas" ?
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          style={(width !== "xs" && width !== "sm") ? {margin: "0 0 -85px 0"}  : {}}
          xs={12}
        >
          <ButtonMUI
            variant="contained" color="primary"
            style={{zIndex: 4, textTransform: "none"}}
            onClick={() => openModal(true)}
          >
            Agregar Tarea
          </ButtonMUI>
          <ButtonMUI
            variant="contained" color="primary"
            style={{zIndex: 4, textTransform: "none", margin: "0 0 0 15px "}}
            onClick={() => setmodalNewWorkPermanent(true)}
          >
            Ingresar Tarea realizada
          </ButtonMUI>
        </Grid> :
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            item
            style={{ zIndex: 4, textTransform: "none"}}
            xs={12}
          >

          <ButtonMUI
            variant="contained" color="primary"
            style={{zIndex: 4, textTransform: "none"}}
            onClick={() => openModal(true)}
          >
            Ingresar Tarea realizada
          </ButtonMUI>
            
          </Grid>
        }
         
        <Grid xs={12}>
          <NavPills
              color="primary"
              alignCenter
              tabs={[
                {
                  tabButton: "Pendientes",
                  tabContent: (
                    <WorkTable 
                      dataArray={dataArray} 
                      setdata={setdata}
                      openModal={openModal}
                      setselectWorkRow={setselectWorkRow}
                      setselectRemoveWorkRow={setselectRemoveWorkRow}
                      seteditWork={seteditWork}
                      setendVisit={setendVisit}
                      setselectUpdateState={setselectUpdateState}
                      complete={false}
                      setsizePatient={setsizePatient}
                      settemperature={settemperature}
                      temperature={temperature}
                      setweight={setweight}
                      setheartRate={setheartRate}
                      setbreathingFrequency={setbreathingFrequency}
                      setoxygenSaturation={setoxygenSaturation}
                      setbloodPressureSystolic={setbloodPressureSystolic}
                      setbloodPressureDiastolic={setbloodPressureDiastolic}
                      setbloodPressureMean={setbloodPressureMean}
                      setpain={setpain}
                      setallergy={setallergy}
                      handleDiagnosis={handleDiagnosis}
                      setUpdatePage={setUpdatePage}
                    />
                  )
                },
                {
                  tabButton: "Finalizadas",
                  tabContent: (
                    <WorkTable 
                      dataArray={dataArray} 
                      setdata={setdata}
                      openModal={openModal}
                      setselectWorkRow={setselectWorkRow}
                      setselectRemoveWorkRow={setselectRemoveWorkRow}
                      seteditWork={seteditWork}
                      setselectUpdateState={setselectUpdateState}
                      complete={true}
                    
                    />
                  )
                }
              ]}
            />
        </Grid>
      </Grid>
    );
}