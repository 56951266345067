import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
import Title from "../../Widgets/OrdeerDashboard/Title";
import Icon from "../../Widgets/OrdeerDashboard/Icon";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import AssignmentIcon from '@material-ui/icons/Assignment';
import ModalQuotationPatient from "./ModalQuotationPatient"

moment.locale("es");

const PatientRegistration = ({
}) => {
    // modal to change dispatch date

    const dispatch = useDispatch();


    // modal that delivers additional information from the patient register

    const [quotationPatientInfo, setquotationPatientInfo] = useState({});
    const [openModalQuotationPatient, setopenModalQuotationPatient] = useState(false);

    const patient = useSelector(state => state.currentGlobalVar.patientClinicOrder);

    const fullName = `${patient?.id_paciente?.nombre} ${patient?.id_paciente?.apellido_paterno} ${patient?.id_paciente?.apellido_materno}`

   

    const handleRedirectionProfile = () => {
        window.open(`/admin/patientDetail/${patient.id_paciente.id}`)
    }

    const handleChargueLastQuotation = () => {

        api.get(`/api/clinica/cotizacion/completa/?paciente=${patient.id_paciente_id}`).then(response => {
            const balanced = response.data.filter((row) => !row.descompensacion)[0]
            const unbalanced = response.data.filter((row) => row.descompensacion)[0]
            setquotationPatientInfo({
              balanced: balanced,
              unbalanced: unbalanced,
            })
            setopenModalQuotationPatient(true)

        }).catch(error => {

            {}}
        );
    }

    return (
        <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
            <Grid item container xs={12} style={{ marginTop: "2rem" }}>
                <Title title="Detalle despacho paciente" variant="h6" />
            </Grid>
            <Grid item container xs={12} justify="flex-end" style={{ marginRight: "1em" }} alignItems="center">
            </Grid>
            <Grid item container xs={12} style={{ marginTop: "2rem", padding: "0 2% 0 2%" }}>
                <Grid item container xs={4}>
                    <Grid item container xs={10}>
                        <Typography variant="h6" gutterBottom style={{ textTransform: "none", fontWeight: 550 }}>
                            {fullName}
                        </Typography>
                    </Grid>
                    <Grid item container xs={2} style={{ margin: "-2% 0 0 -8%" }}>
                        <Icon icon={<AssignmentIcon />} color={grey[50]} action={handleRedirectionProfile} />
                    </Grid>
                </Grid>
                <Grid item container xs={12} justify="left">
                    <Button variant="contained" color="primary" style={{ width: "170px", height: "35px", marginTop: "25px" }} onClick={() => handleChargueLastQuotation()}>Ver cotización</Button>
                </Grid>
            </Grid>
            <Grid item container xs={12} justify="center">
                <Divider style={{ margin: "20px 0", width: "95%", color: indigo[700] }} />
            </Grid>
            
            {openModalQuotationPatient &&
            <ModalQuotationPatient
                open={openModalQuotationPatient}
                closeModal={() => setopenModalQuotationPatient(false)}
                quotationDataPatient={quotationPatientInfo}
            />
            }
        </Grid>
    )
}

export default PatientRegistration;
