import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import apiform from "utils/APIForm";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
import {setmessageGeneral} from "actions/getGlobalAction";
moment.locale("es");

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ModalSVPeso = props => {
  const {
    openModal,
    closeModal,
    tempRowModal,
    handleChange,
    setsizePatient,
    settemperature,
    setweight,
    setheartRate,
    setbreathingFrequency,
    setoxygenSaturation,
    setbloodPressureSystolic,
    setbloodPressureDiastolic,
    setbloodPressureMean,
    setpain,
    setallergy,
    handleDiagnosis,
    setUpdatePage
  } = props;

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [valueAlter, setValueAlter] = useState(0);
  const [openProcess, setOpenProcess] = useState(false);

  const UnitDict = {
    "sizePatient": "cm",
    "temperature": "ºc",
    "weight": "kg",
    "heartRate": "/min", 
    "breathingFrequency": "/min", 
    "oxygenSaturation": "%", 
    "pain": "1-10", 
    "bloodPressure": "mmHg", 
    "bloodPressureMean": "mmHg", 
  }


  const addSignoVital = () => {
    setOpenProcess(true);
    let formData = new FormData();
    formData.append("tipo_signos", tempRowModal.comentarios);
    formData.append("medicion", value);
    formData.append("fecha_hora", moment().format());
    formData.append("id_ficha", tempRowModal.id_ficha);

    if (tempRowModal.comentarios === "sizePatient") {
      setsizePatient({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "temperature") {
      settemperature({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "weight") {
      setweight({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "heartRate") {
      setheartRate({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "breathingFrequency") {
      setbreathingFrequency({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "oxygenSaturation") {
      setoxygenSaturation({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "bloodPressure") {
        setbloodPressureSystolic({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
        setbloodPressureDiastolic({
          value: valueAlter,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "bloodPressureMean") {
      setbloodPressureMean({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "pain") {
      setpain({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "allergy") {
      setallergy({
        value: value,
        date: moment().format("DD-MM-YYYY HH:mm:ss")
      });
      setUpdatePage(true);
    } else if (tempRowModal.comentarios === "diagnosis") {
      handleDiagnosis(value);
      setUpdatePage(true);
    }

    const dictState = {
      diagnosis: "diagnostico",
      allergy: "alergia",
      pain: "dolor",
      bloodPressure: "presión sanguinea",
      oxygenSaturation: "saturación de oxígeno",
      breathingFrequency: "frecuencia respiratoria",
      heartRate: "ritmo cardiaco",
      weight: "peso",
      temperature: "temperatura",
      sizePatient: "talla",
    }

    if(tempRowModal.id_solicitud_paciente && tempRowModal.tipo_tarea.includes("Descomp")){
      const body = {
        comentario: `signo vital agregado: ${dictState[tempRowModal.comentarios] ? dictState[tempRowModal.comentarios] : ""}, valor: ${value}`,
        manual: true,
        tipo_comentario: "RespuestaCompesacion",
        id_descompensacion: null,
        id_usuario: localStorage.getItem("user_id")
      }
      api.post(`api/paciente/comentariosdescompensacion/solicitud/${tempRowModal.id_solicitud_paciente}/`, body).then(() => {
      })
      .catch(error =>
        setmessageGeneral(dispatch, "Error al realizar comentario", "error", "¡Atencion!")
      );
    }

    if (tempRowModal.comentarios === "bloodPressure"){
      let formData = new FormData();
      formData.append("tipo_signos", "bloodPressureSystolic");
      formData.append("medicion", value);
      formData.append("fecha_hora", moment().format());
      formData.append("id_ficha", tempRowModal.id_ficha);

      let formData2 = new FormData();
      formData2.append("tipo_signos", "bloodPressureDiastolic");
      formData2.append("medicion", valueAlter);
      formData2.append("fecha_hora", moment().format());
      formData2.append("id_ficha", tempRowModal.id_ficha);
      const requestBloodPreassureA = apiform.post("api/signosvitales/", formData);
      const requestBloodPreassureD = apiform.post("api/signosvitales/", formData2);
      Promise.all([requestBloodPreassureA, requestBloodPreassureD]).then((request) => {
        handleChange(true, tempRowModal);
        closeModal();
      })
    } else {
      apiform
        .post("api/signosvitales/", formData)
        .then(() => {
          handleChange(true, tempRowModal);
          closeModal();
        })
    }
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Signos Vitales
      </DialogTitle>
      <DialogContent>
      {tempRowModal.descripcion === "Presión arterial" ?
        <Grid item xs={12} container justify="center">
          <Grid item xs={4} style={{padding: "0 5px"}}>
            <TextField
              id="outlined-basic"
              label={"Presión sistólica"}
              variant="outlined"
              value={value}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValue(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
          <Grid item xs={4} style={{padding: "0 5px"}}>
            <TextField
              id="outlined-basic"
              label={"Presión diastólica"}
              variant="outlined"
              value={valueAlter}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValueAlter(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
        </Grid> :
        <Grid item xs={12} container justify="center">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={`${tempRowModal.descripcion} ${UnitDict[tempRowModal.comentarios] || ""}`}
              variant="outlined"
              value={value}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValue(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
        </Grid>
      }
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        {openProcess ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={addSignoVital}
            style={{ textTransform: "none" }}
          >
            Agregar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalSVPeso;

ModalSVPeso.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  tempRowModal: PropTypes.object,
  handleChange: PropTypes.func,
  tempIndex: PropTypes.number,
  setsizePatient: PropTypes.func,
  settemperature: PropTypes.func,
  temperature: PropTypes.object,
  setweight: PropTypes.func,
  setheartRate: PropTypes.func,
  setbreathingFrequency: PropTypes.func,
  setoxygenSaturation: PropTypes.func,
  setbloodPressure: PropTypes.func,
  setpain: PropTypes.func,
  setallergy: PropTypes.func,
  handleDiagnosis: PropTypes.func,
  setUpdatePage: PropTypes.func
};
