import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Grid,
    Typography,
    Checkbox,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import {red, green, orange, indigo} from "@material-ui/core/colors";
import api from "utils/API";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import moment from "moment-timezone";
import {setmessageGeneral } from "actions/getGlobalAction";
import TabCommon from "views/Widgets/Common/TabCommon";
import "moment/locale/es";
moment.locale("es");

export default function ModalEditPatientTENS({
    open,
    handleClose,
    listPatient,
    filterArrayPatient,
    selectPatient,
    handleSelectPatientRotary,
    handleCheckPatientWorker,
    arrayProfessional,
    arrayWorker,
    suggestedTens,
}) {
    const dispatch = useDispatch();
    const [changeButtons, setchangeButtons] = useState(true);
    const [initialCharge, setinitialCharge] = useState(true);
    const [selectedPatient, setselectedPatient] = useState({});
    const [patientInfo, setpatientInfo] = useState(null);
    const [flag, setFlag] = useState(1);
    const [arrayWorkerSelection, setarrayWorkerSelection] = useState([]);
    const [auxArrayProfessional, setauxArrayProfessional] = useState([]);
    const [auxArraySuggestedProfessional, setauxArraySuggestedProfessional] = useState([]);
    const [checkedArray, setcheckedArray] = useState([]);
    const [suggestedCheckArray, setsuggestedCheckArray] = useState([]);



    useEffect(() => {
        if (initialCharge){
          initialData();
        }
        if (changeButtons) {
            setchangeButtons(false);
        }
    });

    console.log(suggestedTens)

    const initialData = () => {
        setinitialCharge(false);
        const tempSelectPatient = listPatient.filter((filterRow) => filterRow.value === selectPatient.id);
        if (tempSelectPatient.length > 0){
            handleSelectPatient(tempSelectPatient[0]);
        }
        const tempArrayWorkerId = arrayProfessional.reduce((accum, obj) => ([
            ...accum,
            obj.id,
        ]), []);

        const tempArray = arrayWorker.reduce((accum, obj) => ([
            ...accum,
            {
                ...obj,
                check: tempArrayWorkerId.includes(obj.id),
                suggest: suggestedTens.includes(obj.id),
                nombre: `${obj.first_name} ${obj.last_name}`,
                direccion: `${obj.id_ficha.direccion_calle} ${obj.id_ficha.direccion_departamento} ${obj.id_ficha.direccion_numero}, ${obj.id_ficha.direccion_comuna}, ${obj.id_ficha.direccion_region}`,
            },
        ]), []);
        setauxArrayProfessional(tempArray.filter((row) => row.check));
        setauxArraySuggestedProfessional(tempArray.filter((row) => row.suggest))
        setarrayWorkerSelection(tempArray);
        setchangeButtons(true);
    };

    const handleSelectPatient = (row) => {
        setselectedPatient(row);
        const tempInfoPatient = filterArrayPatient.filter((filterRow) => filterRow.id === row.value);
        if (tempInfoPatient.length > 0){
            setpatientInfo(tempInfoPatient[0]);
        }
    };

    const handleDelete = (row) => {
        const tempArray = arrayWorkerSelection;
        const index = arrayWorkerSelection.findIndex((tempRow) => tempRow.id == row.id)
        tempArray[index].check = false;
        setarrayWorkerSelection([...tempArray]);
        setauxArrayProfessional(auxArrayProfessional.filter((subRow) => row.id !== subRow.id));
        setchangeButtons(true);
    };

    const handleDeleteSuggest = (row) => {
        const tempArray = arrayWorkerSelection;
        const index = arrayWorkerSelection.findIndex((tempRow) => tempRow.id == row.id)
        tempArray[index].suggest = false;
        setarrayWorkerSelection([...tempArray]);
        setauxArraySuggestedProfessional(auxArraySuggestedProfessional.filter((subRow) => row.id !== subRow.id));
        setchangeButtons(true);
    };

    const handleAddTens = () => {
        setFlag(2);
        handleFilter();
        setcheckedArray([...auxArrayProfessional]);
    };

    const handleChange = (check, row) => {
        const tempArray = arrayWorkerSelection;
        const index = arrayWorkerSelection.findIndex((tempRow) => tempRow.id == row.id)
        tempArray[index].check = check;
        const tempCheckedArray = tempArray.filter((tempRow) => tempRow.check)
        setchangeButtons(true);
        setarrayWorkerSelection([...tempArray]);
        setcheckedArray(tempCheckedArray)
        handleFilter();
    };

    const handleSuggestedChange = (suggest, row) => {
        const tempArray = arrayWorkerSelection;
        const index = arrayWorkerSelection.findIndex((tempRow) => tempRow.id == row.id)
        tempArray[index].suggest = suggest;
        const tempCheckedArray = tempArray.filter((tempRow) => tempRow.suggest)
        setchangeButtons(true);
        setarrayWorkerSelection([...tempArray]);
        setsuggestedCheckArray(tempCheckedArray)
        handleFilter();
    };

    const handleFilter = () => {
        setchangeButtons(true);
    };

    const handleAddTensPatient = () => {
        setauxArrayProfessional([...checkedArray]);
        setauxArraySuggestedProfessional([...suggestedCheckArray]);
        setcheckedArray([])
        setsuggestedCheckArray([])
        setFlag(1);
    }

    const handleSaveChanges = () =>  {
        const array_id_professional = auxArrayProfessional.map((obj) => obj.id);
        const array_suggestesdid_professional = auxArraySuggestedProfessional.map((obj) => obj.id);
        const body = {
            "lista_usuarios": array_id_professional,
            "lista_sugeridos": array_suggestesdid_professional,
            "ficha_paciente": selectPatient.id_ficha_paciente.id,
        };
        api.put(`api/clinica/pacientes/asignar/tens/`, body ).then((response) => {
            handleSelectPatientRotary(selectPatient)
            handleClose();
            setmessageGeneral(dispatch, "TENS asignadas", "success", "¡Exito!");
        });
    };

    const header = [
        {label:  "TENS"},
        {label: "Dirección"},
        {eval: false, label: "Pacientes asignados"},
        {label: "Acción"},
    ]

    const headerActions = [
        {label:  "TENS"},
        {label: "Dirección"},
        {eval: false, label: "Pacientes asignados"},
        {label: "Acción"},
        {label: "Sugeridos"},
    ]

    const dataEditableTENS = arrayWorkerSelection.map((row, index) => {
        const _selectCheck_ =  <Checkbox value="remember"  checked={row.check}  onChange={(event) => handleChange(event.target.checked, row)} color="primary" />
        const _suggestselectCheck_ =  <Checkbox value="remember"  checked={row.suggest}  onChange={(event) => handleSuggestedChange(event.target.checked, row)} color="primary" />

        const _buttonShow_ = <Button
                mr={2}
                size={"small"}
                variant="contained"
                onClick={() => handleCheckPatientWorker(row)}
                >
                Ver
            </Button>

        return [
            {value: row.nombre},
            {value: row.direccion},
            {_value_: _buttonShow_},
            {value: row.check, _value_: _selectCheck_},
            {value: row.suggest, _value_: _suggestselectCheck_},
        ]
    });

    const dataSelectedSuggestedTENS = auxArraySuggestedProfessional.map((row, index) => {
        const _buttonDelete_ = <Button
            mr={2}
            size={"small"}
            variant="contained"
            style={{
                backgroundColor: red[500],
                color: red[50],

            }}
            onClick={() => handleDeleteSuggest(row)}
            >
            Eliminar
        </Button>

        return [
            {value: row.nombre},
            {value: row.direccion},
            {value: row.pacientes_asignados},
            {_value_: _buttonDelete_},
        ]
    })
    
    const dataSelectedTENS = auxArrayProfessional.map((row, index) => {
        const _buttonDelete_ = <Button
            mr={2}
            size={"small"}
            variant="contained"
            style={{
                backgroundColor: red[500],
                color: red[50],

            }}
            onClick={() => handleDelete(row)}
            >
            Eliminar
        </Button>

        return [
            {value: row.nombre},
            {value: row.direccion},
            {value: row.pacientes_asignados},
            {_value_: _buttonDelete_},
        ]
    })

    const _infoPatient_ = <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        item
        xs={12}
        style={{margin: "10px 0 10x 0"}}
    >
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >
            <Typography variant="caption" gutterBottom> {`Nombre: ${patientInfo?.nombre} ${patientInfo?.apellido_paterno} ${patientInfo?.apellido_materno}`}</Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >
            <Typography variant="caption" gutterBottom> {`RUT: ${patientInfo?.rut}`}</Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >
            <Typography variant="caption" gutterBottom> {`Dirección: ${patientInfo?.direccion_calle} ${patientInfo?.direccion_numero} ${patientInfo?.direccion_casa_depto} ${patientInfo?.direccion_block}`}</Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >
            <Typography variant="caption" gutterBottom>{`Diagnostico: ${patientInfo?.diagnostico ? patientInfo.diagnostico : "Sin diagnostico"}`}</Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >
            <Typography variant="caption" gutterBottom>
                {`Edad: ${patientInfo?.nacimiento ? `${moment().diff(patientInfo?.nacimiento, 'years')} años` : "Sin actualizar"}`}
            </Typography>
        </Grid>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={6}
            style={{margin: "10px 0 10x 0"}}
        >

        </Grid>
    </Grid>

    const listTab = [
        {label:"TENS actuales del paciente",
        _value_: <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <TableComponent
                    headers={header}
                    data={dataSelectedTENS}
                    styleTableContainer={{height: "300px"}}
                />
            </Grid>
        </>
        },
        {label:"TENS sugeridos para el paciente",
        _value_: <>
           <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <TableComponent
                    headers={header}
                    data={dataSelectedSuggestedTENS}
                    styleTableContainer={{height: "300px"}}
                />
            </Grid>
        </>
        },
    ]


    const _content_ = <div className="form-row center" style={{ marginBottom: "1rem" }}>
        {changeButtons === false &&
            <div></div>
        }
        {flag === 1 ?
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 10x 0"}}
            >
                <Typography variant="body2" gutterBottom>{`¿Cuál paciente quieres revisar?`}</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <DetailSelect
                    value={selectedPatient}
                    validation={false}
                    onchange={(value) => setselectedPatient(value)}
                    list={listPatient}
                />
            </Grid>
            {_infoPatient_}
            <TabCommon
                data={listTab}
            />
        </Grid> :
        flag === 2 &&
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            >
            {_infoPatient_}
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <TableComponent
                    headers={headerActions}
                    data={dataEditableTENS}
                    styleTableContainer={{height: "500px"}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Typography variant="caption" gutterBottom>
                        {`Se ha seleccionado a los siguientes ${checkedArray.length} TENS: `}
                </Typography>
                {checkedArray.map((row, index) => (
                    <Typography variant="caption" gutterBottom>
                        {`${row.nombre}${index < checkedArray.length-1 ? "," : "."}`}
                    </Typography>
                ))}
            </Grid>
        </Grid>
        }
    </div>

    const _actions_ = <>
        {flag === 1 ?
        <>
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: green[500],
                color: green[50],

            }}
            onClick={handleAddTens}
        >
            Agregar TENS
        </Button>
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: indigo[500],
                color: indigo[50],
                margin: "0 10px"
            }}
            onClick={handleSaveChanges}
        >
            Guardar Cambios
        </Button>
        {patientInfo ?
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: indigo[500],
                color: indigo[50],

            }}
            onClick={() => window.open(`/admin/patientDetail/${patientInfo.id}`)}
            >
            Ver ficha
        </Button> :
        <Button
            mr={2}
            variant="contained"
            disabled
        >
            Ver ficha
        </Button>
        }
        </> :
        flag === 2 &&
        <Button
            mr={2}
            variant="contained"
            style={{
                backgroundColor: green[500],
                color: green[50],

            }}
            onClick={handleAddTensPatient}
        >
            Agregar TENS marcadas
        </Button>}
    </>

    return (
        <ModalDialog
            open={open}
            onClose={handleClose}
            title={ flag === 1 ? "Editar TENS del paciente" : flag === 2 ? `Agregar TENS` : ""}
            maxWidth="lg"
            _content_={_content_}
            _actions_ = {_actions_}
        />

    );
}