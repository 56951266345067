import React, {useState, useEffect } from 'react';
import {
    Grid,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


export default function ModalChargePatient({open, handleClose, setselectPatient}) {
    const [patient, setpatient] = useState({});
    const [listPatient, setlistPatient] = useState([]);
    const [arrayPatient, setarrayPatient] = useState([]);
    const [validation, setvalidation] = useState(false);
    const [typepatient, settypepatient] = useState("");

    useEffect(() => {
        validateForm();
    });

    const validateForm = () => {
        const arrayValidation = [
            patient !== "",
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const handleOnChange = (value, set) => {
        set(value);
    };

    const chargePatient = () => {
        setselectPatient(null);
        const tempDataPatient = arrayPatient.filter((row) => row.id === patient.value );
        localStorage.setItem("patientFile",JSON.stringify(tempDataPatient[0]))
        setselectPatient(tempDataPatient[0]);
        handleClose();
    };

    const habdleUpdatePatient = (clinic=null)=> {
        const codeCharge = localStorage.getItem("tipo_perfil")
        let url = "api/paciente/";
        let tens = ''
        if (codeCharge === "CLITecEnfBas"){
            tens =`tens=${localStorage.getItem("user_id")}&`
        }
        if (clinic){
            url = `${url}?${tens}tratamiento=clinica`
            settypepatient("clinico")
        } else {
            url = `${url}?${tens}tratamiento=domiciliario`
            settypepatient("domiciliario")
        }
        api.get(url).then((response) => {
            setarrayPatient(response.data)
            setlistPatient(response.data.map((row) => {
                return {
                    value: row.id,
                    label: `(${row.rut}) ${row.nombre} ${row.apellido_paterno} ${row.apellido_materno}`,
                };
                })
            );
            })
      }

    const _content_ = <div>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
                xs={12}
                style={{padding: "5px 0 10px 0"}}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => habdleUpdatePatient()}
                >
                    ver pacientes domicilio
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => habdleUpdatePatient(true)}
                >
                    ver pacientes Clinica San Gerardo
                </Button>
            </Grid>
            {listPatient.length > 0 && 
            <Grid
                item
                xs={12}
            >
                <DetailSelect
                    label={`Paciente ${typepatient}`}
                    value={patient}
                    onchange={(value) => { handleOnChange(value, setpatient)}}
                    list={listPatient}
                    validation={false}
                />
            </Grid>
            }
        </Grid>
    </div>

    const _actions_ = <>
        <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
        >
            Cerrar
        </Button>
        { validation ?
        <Button
            color="primary"
            variant="contained"
            onClick={chargePatient}
        >
            Cargar
        </Button>
        :
        <Button
            disabled
            color="primary"
            variant="contained"
        >
            Cargar
        </Button>
        }
    </>

    return (
    <ModalDialog
        open={open}
        onClose={handleClose}
        title={"Cargar Paciente"}
        _content_={_content_}
        _actions_ = {_actions_}
        maxWidth={'sm'}
    />
    );
}