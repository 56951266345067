import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Paper,
    Grid,
    Typography,
    Backdrop,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import {dictMonth} from "utils/validationFunction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  },
}));

export default function ModalScheduleTime({
    open,
    handleClose,
    selectedModule,
    professional,
    selectRowDetails,
    setupdateChecked,
    setselectedModule,
    handleUpdateState,
    modeView,
}) {
    const classes = useStyles();
    const startDate = moment(selectedModule.start);
    const endDate = moment(selectedModule.end);

    const dictTypeWork = useSelector(state => state.currentList.DICCIONARIO_TIPO_TAREAS_FICHA);

    const handleSaveReservation = () => {
        const bodyBulk = { "tareas_paciente": professional.map((row) => {
            return {
                tipo_tarea: selectRowDetails[0].tipo_tarea,
                fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
                id_cargo_asignado: row.cargo_id.id,
                id_usuarios: row.id,
                descripcion: "Ir a visita inicial",
                estado: "Incompleto",
                id_ficha: selectRowDetails[0].id_ficha.id,
                id_tarea_padre: selectRowDetails[0].id,
            }
        })};
        const body = {
            id: selectRowDetails[0].id,
            tipo_tarea: selectRowDetails[0].tipo_tarea,
            fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            fecha_final: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
            id_cargo_asignado: selectRowDetails[0].id_cargo_asignado.id,
            id_usuarios: selectRowDetails[0].id_usuarios.id,
            descripcion: "Visita inicial",
            estado: "Incompleto",
            id_ficha: selectRowDetails[0].id_ficha.id,
            id_tarea_padre: selectRowDetails[0].id,
            id_solicitud_paciente: selectRowDetails[0].id_solicitud_paciente.id,
        };
        const requestFinish = api.post(`api/solicitudvisitainicial/finalizar/`, body)
        const requestBulkWork = api.post(`api/paciente/tareas/bulk/`, bodyBulk);
        Promise.all([
            requestBulkWork,
            requestFinish,
        ]).then((request) => {
            setupdateChecked(true);
            handleUpdateState(request[1].data);
            setselectedModule(null);
            handleClose();
        });
    };

    const handleEditReservation = () => {
        const bodyBulk = { "tareas_paciente":professional.map((row) => {
            return {
                tipo_tarea: selectRowDetails[0].tipo_tarea,
                fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                fecha_final: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
                descripcion: selectRowDetails[0].descripcion,
                estado: "Incompleto",
                id_ficha: selectRowDetails[0].id_ficha.id,
                id_tarea_padre: selectRowDetails[0].id,
                id_cargo_asignado: row.cargo_id.id,
                id_usuarios: row.id,
            }
        })};
        const body = {
            fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            fecha_final: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        };
        const requestFinish = api.patch(`api/tareaspaciente/${selectRowDetails[0].id}`, body)
        const requestBulkWork = api.delete(`api/solicitudvisitainicial/tareas/${selectRowDetails[0].id}/limpiar/`);
        Promise.all([
            requestBulkWork,
            requestFinish,
        ]).then((request) => {
            api.post(`api/paciente/tareas/bulk/`, bodyBulk).then((response) => {
                setupdateChecked(true);
                handleUpdateState(request[1].data);
                setselectedModule(null);
                handleClose();
            });
        });
    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <h3 id="spring-modal-title">{`Se ${modeView} la siguiente visita`}</h3>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        item
                        xs={12}
                        style={{padding: "5px"}}
                    >
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Tipo de visita: ${dictTypeWork[selectRowDetails[0].tipo_tarea]?.label}`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Paciente:  ${selectRowDetails[0].id_ficha ?
                                `${selectRowDetails[0].id_ficha.id_paciente.nombre} ${selectRowDetails[0].id_ficha.id_paciente.apellido_paterno} ${selectRowDetails[0].id_ficha.id_paciente.apellido_materno}` :
                                `No disponible`
                            }`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Profesionales que asisten:`}
                        </Typography>
                        {professional.map((row, index) => (
                            row.check &&
                            <Typography  style={{margin: "0 0 0 10px"}} key={`${index}-profesional_select`} variant="caption" display="block" gutterBottom>
                              {`${row.cargo_id.nombre_corto} - ${row.first_name} ${row.last_name}`}
                            </Typography>
                        ))}
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Fecha: ${moment(startDate).format("DD")} de ${dictMonth[parseInt(moment(startDate).format("MM"))]}`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Hora inicio: ${moment(startDate).format("HH:mm")}`}
                        </Typography>
                        <Typography  variant="caption" display="block" gutterBottom>
                            {`Hora de fin: ${moment(endDate).format("HH:mm")}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 0 0"}}
                >
                    {modeView === "agendará" ?
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        color="primary"
                        onClick={handleSaveReservation}
                    >
                        Guardar
                    </Button> :
                    <Button
                        mr={2}
                        variant="contained"
                        className="text-white"
                        color="primary"
                        onClick={handleEditReservation}
                    >
                        Guardar
                    </Button>
                    }
                    <Button
                        mr={2}
                        variant="contained"
                        style={{backgroundColor: red[500], marginRight: "10px"}}
                        className="text-white"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}