import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';

import {
  Button as ButtonMUI,
  Grid,

  TextField
} from "@material-ui/core";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import { red, green } from "@material-ui/core/colors";
import api from "utils/API";
import NumberFormat from "react-number-format";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");



function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
    />
  );
}

export default function ModalNewWorkPermanent({
  open,
  handleClose,
  array,
  setArray,
  dataFile,
  setsizePatient,
  settemperature,
  setweight,
  setheartRate,
  setbreathingFrequency,
  setoxygenSaturation,
  setbloodPressureSystolic,
  setbloodPressureDiastolic,
  setbloodPressureMean,
  setpain,
  setallergy,
}) {
  const dispatch = useDispatch()
  const [type, setType] = useState({});
  const [description, setdescription] = useState("");
  const [validation, setvalidation] = useState(false);
  const [medicine, setmedicine] = useState({});
  const [dose, setdose] = useState(1);
  const [typeAmount, settypeAmount] = useState({});
  const [typeBalance, settypeBalance] = useState({value: "Ingresos", label: "Ingresos" });
  const [nameBalance, setnameBalance] = useState({});
  const [amountBalance, setamountBalance] = useState("0");
  const [typeGeneric, settypeGeneric] = useState({});
  const [typevital, settypevital] = useState({});
  const [changeButtons, setchangeButtons] = useState(true);
  const [medicalIndications, setMedicalIndications] = useState(false);
  const [listMeasureUnits, setListMeasureUnits] = useState([]);
  const [listBalance, setlistBalance] = useState([]);
  const [value, setValue] = useState(0);
  const [valueAlter, setValueAlter] = useState(0);

  const [refresh, setrefresh] = useState(false);

  const listTypeAmountBenefit = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
  const listTypeWorkFilePatient = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA_PACIENTE);
  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listFood = useSelector(state => state.currentList.listFood);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const listSigns = useSelector(state => state.currentList.LISTA_SIGNOS_VITALES);

  const listTypeWork = [
    {value: 'MedHab', label: 'Medicinas Habituales'},
    {value: 'BalanceHidrico', label: 'Balance Hidrico'},
    {value: 'General', label: 'General'},
    {value: 'SignosVitales', label: 'Signos Vitales'},
  ]

  const listTypeGeneral = [
    {value: "Aseo Cama", label: "Aseo Cama"},
    {value: "Aseo Genital", label: "Aseo Genital"},
    {value: "Aseo Bucal", label: "Aseo Genital"},
    {value: "Cambios de posición", label: "Cambios de posición"},
    {value: "Cambio bajada alimentación", label: "Cambio bajada alimentación"},
    {value: "Cambio sábana", label: "Cambio sábana"},
    {value: "Cambio bolsa", label: "Cambio bolsa"},
    {value: "Cambio collarín" , label: "Cambio collarín"},
    {value: "Cuidados de Sonda", label: "Cuidados de Sonda"},
    {value: "Control Peso", label: "Control Peso"},
    {value: "Cuidados de VVP", label: "Cuidados de VVP"},
    {value: "Curación de TQT", label: "Curación de TQT"},
    {value: "Curación de GTT", label: "Curación de GTT"},
    {value: "CTT", label: "CTT"},
    {value: "Desinfección unidad paciente", label: "Desinfección unidad paciente"},
    {value: "Desinfección de equipos", label: "Desinfección de equipos"},
    {value: "Lubricación piel", label: "Lubricación piel"},
    {value: "Medición CC", label: "Medición CC"},
    {value: "Muda", label: "Muda"},
    {value: "Recolección de Orina", label: "Recolección de Orina"},
    {value: "Revisión de equipos", label: "Revisión de equipos"},
    {value: "Revisión de insumos", label: "Revisión de insumos"},
    {value: "Revisión de medicamentos", label: "Revisión de medicamentos"},
    {value: "Sondeo Vesical", label: "Sondeo Vesical"},
  ]

  const UnitDict = {
    "sizePatient": "cm",
    "temperature": "ºc",
    "weight": "kg",
    "heartRate": "/min", 
    "breathingFrequency": "/min", 
    "oxygenSaturation": "%", 
    "pain": "1-10", 
    "bloodPressure": "mmHg", 
    "bloodPressureMean": "mmHg", 
  }



  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
    validateForm();
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (refresh){
      setrefresh(false)
    }
  });

  const validateForm = () => {
    const tempType = type?.value
    const arrayValidation = [
      (
        "MedHab" === tempType &&
        medicine.label !== undefined &&
        dose > 0 &&
        typeAmount.value !== undefined
      ) || (
        "BalanceHidrico" === tempType &&
        nameBalance.value !== undefined &&
        amountBalance > 0
      ) || (
        "General" === tempType &&
        typeGeneric.value !== undefined
      ) || (
        "SignosVitales" === tempType &&
        typevital.value !== undefined &&
        value > 0
      ),
      description !== "" || "BalanceHidrico" === tempType || "SignosVitales" === tempType,
      type.value !== undefined,
    ];
    if (arrayValidation.every((item) => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false); 
    }
  };

  const handleOnChange = (value, set, typeItem="") => {

    if (value.value === "IndicacionesMedicas") {
      setMedicalIndications(true);
    }

    if (value.value === "BalanceHidrico") {
      genListTypeBalance()
    }

    if (["MedHab", "SOS", "Alimentacion"].includes(type.value)){
      console.log(listmeasureunits)
      var listmeasureunits = listTypeAmountBenefit.filter( item =>
        ['ml', 'mg', 'gr', "mcg", "puff"].includes(item.value) );
        setListMeasureUnits(listmeasureunits)
    }
    set(value);
  };

  const genListTypeBalance = () => {
    api.get("api/tipobalancehidrico/").then((response) => {
      setlistBalance(response.data.map((row) => {
        return {...row, value: row.nombre, label: row.nombre}
      }))
    })
  }

  const updateValue = () => {
    const body = {
        tipo_tarea: type.value,
        fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
        fecha_final: moment().format("YYYY-MM-DD HH:mm:ss"),
        id_usuarios: user_id,
        descripcion: `${
          dose > 0 &&
          typeAmount.value !== undefined &&
          medicine.value !== undefined
            ? `${medicine.label}, ${dose} ${typeAmount.label}. `
            : ""
        } ${description}`,
        estado: "Completado",
        id_ficha: dataFile.id,
    }
    if (type.value === "BalanceHidrico"){
        body["tipo_tarea"] = "IndicacionesMedicas"
        body["descripcion"] = nameBalance.value
        body["comentarios"] = typeBalance.value
        body["motivo_rechazo"] = "BalanceHidrico"

        const bodyBalance = {
          "tipo_io": typeBalance.value,
          "fecha_registro": moment().format("YYYY-MM-DD HH:mm:ss"),
          "cantidad": amountBalance,
          "id_tipo_movimiento": nameBalance.id,
          "id_ficha":dataFile.id,
        }

        api.post("api/balancehidrico/", bodyBalance)
        
    } else if (type.value === "General"){
        body["tipo_tarea"] = "IndicacionesMedicas"
        body["descripcion"] = typeGeneric.value
        body["comentarios"] = type.value
        body["motivo_rechazo"] = type.value


        const bodyGeneral = {
          "catgoria": "Estimulaciones",
          "descripcion": description,
          "id_ficha":dataFile.id,
        }

        api.post("api/tipostareasgenerales/", bodyGeneral)
        
    } else if (type.value === "SignosVitales"){
      body["tipo_tarea"] = "IndicacionesMedicas"
      body["descripcion"] = typevital.label
      body["comentarios"] = typevital.value
      body["motivo_rechazo"] = type.value

      const bodySign = {
        "tipo_signos": typevital.value,
        "medicion": value,
        "fecha_hora": moment().format(),
        "id_ficha": dataFile.id,
      }
          
      if (typevital.value === "sizePatient") {
        setsizePatient({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "temperature") {
        settemperature({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "weight") {
        setweight({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "heartRate") {
        setheartRate({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "breathingFrequency") {
        setbreathingFrequency({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "oxygenSaturation") {
        setoxygenSaturation({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "bloodPressure") {
          setbloodPressureSystolic({
            value: value,
            date: moment().format("DD-MM-YYYY HH:mm:ss")
          });
          setbloodPressureDiastolic({
            value: valueAlter,
            date: moment().format("DD-MM-YYYY HH:mm:ss")
          });
      } else if (typevital.value === "bloodPressureMean") {
        setbloodPressureMean({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "pain") {
        setpain({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "allergy") {
        setallergy({
          value: value,
          date: moment().format("DD-MM-YYYY HH:mm:ss")
        });
      } else if (typevital.value === "diagnosis") {
        handleDiagnosis(value);
      }

      if (typevital.value === "bloodPressure"){
        const bodySign = {
          "tipo_signos": "bloodPressureSystolic",
          "medicion": value,
          "fecha_hora": moment().format(),
          "id_ficha": tempRowModal.id_ficha,
        }
        const bodySign2 = {
          "tipo_signos": "bloodPressureDiastolic",
          "medicion": valueAlter,
          "fecha_hora": moment().format(),
          "id_ficha": tempRowModal.id_ficha,
        }
        apiform.post("api/signosvitales/", bodySign);
        apiform.post("api/signosvitales/", bodySign2);
      } else {
        api.post("api/signosvitales/", bodySign)
      }
    }
    api.post(`api/tareaspaciente/`, body).then((result) => {
      const tempArray = [...array]
      tempArray.push(result.data)
      setArray(tempArray)
      handleClose();
    });
  };

  const updateStock = () => {
    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true);
    })
  };

  const _content_ = <div>
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-end"
    item
    xs={12}
  >
    <Grid item xs={4}>
      <DetailSelect
        label="Tipo"
        value={type}
        onchange={(value) => {
          handleOnChange(value, setType);
        }}
        list={listTypeWork}
      />
    </Grid>
    {["MedHab"].includes(type.value) && (
    <Grid item xs={4}>
      <DetailText
        label={`Descripción`}
        placeholder={`Descripción`}
        value={description}
        onchange={(e) => {
          handleOnChange(e.target.value, setdescription);
        }}
      />
    </Grid>
    )}
   
    {["MedHab",].includes(type.value) && (
      <>
      <Grid item xs={4}>
        <DetailSelect
          label="Medicamento"
          value={medicine}
          onchange={(value) => {
            handleOnChange(value, setmedicine, "Medicine");
          }}
          list={listMedical}
        />
      </Grid>
      <Grid item xs={4}>
        <DetailNumber
          label={`Dosis`}
          placeholder={`Dosis`}
          value={dose}
          onchange={(e) => {
            handleOnChange(e.target.value, setdose);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo/Cantidad"
          value={typeAmount}
          onchange={(value) => {
            handleOnChange(value, settypeAmount);
          }}
          list={listMeasureUnits}
        />
      </Grid>
      </>

    )}
    {["BalanceHidrico"].includes(type.value) && (
      <>
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo Balance"
          value={typeBalance}
          onchange={(value) => {
            handleOnChange(value, settypeBalance);
          }}
          list={[{value: "Ingresos", label: "Ingresos"}, {value: "Egresos", label: "Egresos"}]}
        />
      </Grid>
      <Grid item xs={4}>
        <DetailSelect
          label="nombre"
          value={nameBalance}
          onchange={(value) => {
            handleOnChange(value, setnameBalance);
          }}
          list={listBalance.filter((filtered) =>  filtered.tipo_io === typeBalance.value)}
        />
      </Grid>
       <Grid item xs={4}>
        <DetailNumber
          label={`Cantidad ml`}
          placeholder={`Cantidad ml`}
          value={amountBalance}
          onchange={(e) => {
              if (/^[0-9.\b]+$/.test(e.target.value)) {
                handleOnChange(e.target.value, setamountBalance)
              }
          }}
        />
      </Grid>
      </>
      
    )}
    {["SignosVitales"].includes(type.value) && (
      <>
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo signo vital"
          value={typevital}
          onchange={(value) => {
            handleOnChange(value, settypevital);
          }}
          list={listSigns}
        />

      </Grid>
      {typevital.label === "Presión arterial" ?
        <>
          <Grid item xs={4} style={{padding: "0 5px"}}>
            <TextField
              id="outlined-basic"
              label={"Presión sistólica"}
              variant="outlined"
              value={value}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValue(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
          <Grid item xs={4} style={{padding: "0 5px"}}>
            <TextField
              id="outlined-basic"
              label={"Presión diastólica"}
              variant="outlined"
              value={valueAlter}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValueAlter(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
        </> :
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={`${typevital.label} ${UnitDict[typevital.value] || ""}`}
              variant="outlined"
              value={value}
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
              onChange={e => {
                if (/^[0-9\b]+$/.test(e.target.value)) {
                  setValue(parseInt(e.target.value));
                }
              }}
            />
          </Grid>
          }
      </>
    )}
    {["General"].includes(type.value) && (
      <>
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo tarea general"
          value={typeGeneric}
          onchange={(value) => {
            handleOnChange(value, settypeGeneric);
          }}
          list={listTypeGeneral}
        />
      </Grid>
      <Grid item xs={4}>
        <DetailText
          label={`Descripción`}
          placeholder={`Descripción`}
          value={description}
          onchange={(e) => {
            handleOnChange(e.target.value, setdescription);
          }}
        />
      </Grid>
      </>
      
    )}
    {refresh && <div></div>}
  </Grid>
  

{["MedHab", "SOS", "Alimentacion"].includes(type.value) && (
<UpdateStockButton
  updateStock={updateStock}
/>
)}
</div>

const _actions_ = <>
 {validation ? (
      <ButtonMUI
        mr={2}
        variant="contained"
        className="text-white"
        style={{ backgroundColor: green[500] }}
        onClick={updateValue}
      >
        Guardar
      </ButtonMUI>
    ) : (
      <ButtonMUI
        disabled
        mr={2}
        variant="contained"
        className="text-white"
      >
        Guardar
      </ButtonMUI>
    )}
    <ButtonMUI
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </ButtonMUI>
  </>

  return (
  <ModalDialog
      open={open}
      onClose={handleClose}
      title={`COMPLETAR TAREA PERMANENTE`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="lg"
  />
  )
}
