import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
import { grey, indigo, orange, green, red } from "@material-ui/core/colors";
import { ButtonTextMUI as ButtonCustom } from "utils/componentsStyle";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DialogDefault from "../../Widgets/OrdeerDashboard/DialogDefault";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TableComponent from "views/Widgets/Common/TableComponent";
import allActions from "actions";

moment.locale("es");

const PatientRegistrationPatientFile = ({
    dataPatient,
    listaRevisar,
    setListaRevisar
}) => {
    // modal to change dispatch date

    const dispatch = useDispatch();


    // modal that delivers additional information from the patient register
    const [openRegisterDetail, setopenRegisterDetail] = useState(false);
    const [patientRegisterDetail, setpatientRegisterDetail] = useState([]);


    const fullName = `${dataPatient.nombre} ${dataPatient.apellido_paterno} ${dataPatient.apellido_materno}`

    const RegisterContentModal = <Grid item container xs={12}>
        <Grid item container xs={6}>
            <Typography variant={"body2"} style={{ color: indigo[500] }}>Paciente</Typography>
        </Grid>
        <Grid item container xs={6}>
            <Typography variant={"body2"} style={{ color: indigo[500] }}>Fecha y hora del registro</Typography>
        </Grid>
        <Grid item container xs={6}>
            <Typography >{fullName}</Typography>
        </Grid>
        <Grid item container xs={6}>
            <Typography >{moment(patientRegisterDetail.fecha_envio).format("DD/MM/YYYY | HH:MM")}</Typography>
        </Grid>
        <Grid item container xs={12}>
            <Typography variant={"body2"} style={{ color: indigo[500], marginTop: "25px" }}>Detalle</Typography>
        </Grid>
        <Grid item container xs={12}>
            <Typography style={{ fontSize: "12px" }}>{patientRegisterDetail.id_hoja_registro_id__observaciones}</Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
            <Button variant="contained" color="primary" style={{ width: "55px", height: "24px", marginTop: "25px" }} onClick={() => setopenRegisterDetail(false)}>CERRAR</Button>
        </Grid>
    </Grid>



    const handleStatusSave = (dataRecordSheet) => {
        console.log(dataRecordSheet, listaRevisar)

        const stateItem = !dataRecordSheet.revisado ? "activar" : "desactivar";
        api.put(
            `/api/clinica/pedidosprogramados/paciente/${dataPatient.id}/hojaregistro/${dataRecordSheet.id}/${stateItem}/`
        ).then(response => {
            const tempList = listaRevisar.map(obj => {
                if (obj.id === dataRecordSheet.id) {
                    return {...obj, revisado: !dataRecordSheet.revisado};
                }
                return obj;
            });

            setListaRevisar(tempList)

        })
    };



    const headerRegisterPatient = [
        { label: 'Fecha'},
        { label: 'Cargo'},
        { label: 'Estado'},
        { label: 'Modificar estado' },
        { eval: false, label: 'Detalle'}
    ]

    const dataRegisterPatient = listaRevisar.map(item => {

        let statusIcon = "Pendiente"
        let colorIcon = orange[500]

        let colorButton = green[500]
        let statusButton = "Completado"
        let icon = <CheckCircleOutlineIcon/>

        if (item.revisado === true) {
            statusButton = "Pendiente"
            statusIcon = "Completado"
            colorButton = orange[500]
            colorIcon = green[500]
            icon = <ReplayIcon fontsize={"small"}/>
        }

        const _status_ =  <span style={{ color: colorIcon, display: "inline-flex" }} ><FiberManualRecordIcon style={{ marginTop: '-5px' }} /> {statusIcon}</span>;
        const _editStatus_ =  <ButtonCustom aria-label="close" variant={"outlined"} style={{ color: colorButton }} onClick={() => {handleStatusSave(item)}}>
            {icon}  Marcar como {statusButton.toLowerCase()}
        </ButtonCustom>

        const _detail_ = <ButtonCustom aria-label="close" variant={"outlined"} style={{ color: indigo[500] }} onClick={() => {
            setopenRegisterDetail(true)
            setpatientRegisterDetail(item)
        }}>Ver detalle </ButtonCustom>


        return [
            {eval:true, value: moment(item.fecha_envio).format("DD/MM/YYYY"),},
            {eval:true, value: item?.id_hoja_registro_id__id_cargo__nombre_corto},
            {eval:true, value: statusIcon, _value_: _status_},
            {eval:true, value: statusButton, _value_: _editStatus_},
            {eval:false, _value_: _detail_},
        ]
    })


    return (
        <Grid item container xs={12} style={{ marginTop: "2em", backgroundColor: "#fff", borderRadius: "5px" }}>
            <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }} >
                <Grid item container direction="row" alignContent="flex-start" xs={3}>
                    <Grid item container xs={12} style={{margin: "0 0 20px 0"}}>
                        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>
                            Registro paciente
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Typography gutterBottom style={{ textTransform: "none" }}>
                            En esta sección podrás revisar nuevos registros sobre medicamentos e insumos que necesita el paciente,
                            los que te servirán para editar ya sea el paquete o el próximo envío de acuerdo a lo que se requiera, en la sección <b>Detalle de pedidos</b> que podrás ver a continuación.
                            Recuerda marcar el registro como completado una vez que hayas realizado lo que se especifica en él
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={1}></Grid>
                <Grid item container xs={8}>
                    <TableComponent
                        headers={headerRegisterPatient}
                        data={dataRegisterPatient}
                        size={'small'}
                        pageSize={5}
                    />
                    {<DialogDefault maxWidth={'md'} fullWidth={'md'} title={"REGISTRO"} content={RegisterContentModal} openParameter={openRegisterDetail} setParamenter={setopenRegisterDetail} />}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PatientRegistrationPatientFile;
