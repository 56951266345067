import React, {useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal,
    Backdrop,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import {ButtonTextMUI as Button} from "utils/componentsStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import {red, green} from "@material-ui/core/colors";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: "all 500ms linear",
  },
  paper: {
    transition: "all 500ms linear",
    padding: "2% 4% 3%",
  }
}));


export default function ModalUpdateStateWork({
    open,
    handleClose,
    row,
    array,
    setArray,
    setmessage,
    dataFile,
    setdataFile,
}) {
    const classes = useStyles();

    const dictTypeWorkFile = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA);

    const handleChangeState = (value) => {
        const tempArray = array;
        const state = value ? "Completado" : "Incompleto";
        const body = {estado: state};

        if(row.id_solicitud_paciente && row.tipo_tarea.includes("Descomp")){
            const commentBody = {
              comentario: `${dictTypeWorkFile[row.tipo_tarea]?.label}, descripción: ${row.descripcion}. cambio de estado a ${state}, `,
              manual: true,
              tipo_comentario: "RespuestaCompesacion",
              id_descompensacion: null,
              id_usuario: localStorage.getItem("user_id")
            }
            api.post(`api/paciente/comentariosdescompensacion/solicitud/${row.id_solicitud_paciente}/`, commentBody).then(() => {
            })
        }


        if (body.tipo_tarea === "VisitaInicial"){
            api.get(`/api/solicitudvisitainicial/tarea/${body.id}/finalizar/`).then((response) => {
                const tempDataFile = {...dataFile};
                tempDataFile.estado_paciente = "Visitado";
                setArray(tempArray.map((subRow) => {
                    const tempSubRow = {...subRow};
                    if (body.id_tarea_padre === subRow.id_tarea_padre || body.id_tarea_padre === subRow.id){
                        tempSubRow.estado = state
                    }
                    return {...tempSubRow}
                }));
                setdataFile(tempDataFile);
                handleClose();
                setmessage(`Estado de visita inicial actualizado`);
            })
        } else {
            api.patch(`api/tareaspaciente/${row.id}`, body).then((response) => {
                setArray(tempArray.map((subRow) => {
                    const tempSubRow = {...subRow};
                    if (row.id === subRow.id){
                        tempSubRow.estado = state
                    }
                    return {...tempSubRow}
                }));
                handleClose();
                setmessage(`Estado de tarea actualizado`)
            });
        }



    };

    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
            borderRadius: '4px'
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
            className={classes.paper}
            style={{
                maxHeight: "900px",
                overflow: "auto",
            }}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                <h3 id="spring-modal-title">{`Tareas`}</h3>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                    <Typography  variant="caption" display="block" gutterBottom>
                        {`¿Ha finalizado la tarea?`}
                    </Typography>
                </Grid>
                {row.tipo_tarea === "VisitaInicial" &&
                <SnackbarContent
                    message={`Al completar está tarea se dará por finalizado el proceso de visita inicial`}
                    color="info"
                />
                }
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    item
                    xs={12}
                    style={{margin: "10px 0 10x 0"}}
                >
                  <Button
                      mr={2}
                      variant="contained"
                      className="text-white"
                      style={{backgroundColor: green[500], margin: "0 5px"}}
                      onClick={() => handleChangeState(true)}
                  >
                      Si
                  </Button>
                  <Button
                      mr={2}
                      variant="contained"
                      style={{backgroundColor: red[500], margin: "0 5px"}}
                      className="text-white"
                      onClick={() => handleChangeState(false)}
                  >
                      No
                  </Button>
                </Grid>
            </Grid>
        </Paper>
      </Modal>
  );
}