import React, { useState, useEffect } from 'react'
import api from 'utils/API'
import moment from "moment-timezone";
import "moment/locale/es";

import { TypographyMui } from 'utils/componentsStyle';

import { makeStyles } from '@material-ui/core/styles'
import { TextField, Box, Button, Tabs, Tab, Fab } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ListIcon from '@material-ui/icons/List'
import DateRangeIcon from '@material-ui/icons/DateRange'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import pill from '../../../../assets/img/icons/stock/medicamentos.svg'
import pillDisabled from '../../../../assets/img/icons/stock/medicamentos-disabled.svg'
import label from '../../../../assets/img/icons/stock/insumos.svg'
import labelDisabled from '../../../../assets/img/icons/stock/insumos-disabled.svg'
import pc from '../../../../assets/img/icons/stock/equipos.svg'
import pcDisabled from '../../../../assets/img/icons/stock/equipos-disabled.svg'


const actionButtons = [
  { text: 'Medicamento', value: 'medicamentos', icon: pill, button: 'orangeButton', query: 'grupo="medicamentos"&' },
  { text: 'Insumos', value: 'insumos', icon: label, button: 'blueButton', query: 'grupo="insumos"&' },
  { text: 'Equipos', value: 'equipos', icon: pc, button: 'purpleButton', query: 'grupo="equipos"&' },
]

const filterQueries = ['grupo=medicamentos&', 'grupo=insumos&', 'grupo=equipos&']

const useStyles = makeStyles({
  tabs: {
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  orangeButton: {
    backgroundColor: 'rgba(244, 67, 54, 0.08)',
    color: '#F44336',
    border: '1px solid #F44336'
  },
  blueButton: {
    backgroundColor: 'rgba(33, 150, 243, 0.08)',
    color: '#2196F3',
    border: '1px solid #2196F3'
  },
  purpleButton: {
    backgroundColor: 'rgba(156, 39, 176, 0.08)',
    color: '#9C27B0',
    border: '1px solid #9C27B0'
  },
  disabledButton: {
    backgroundColor: 'transparent',
    color: '#BDBDBD',
    border: '1px solid #BDBDBD'
  },
});

function HomeStockActions({ open, stockData, stockList, setIsDialogOpen, setopenDownloadStock, setTab, setStockList, setStockCalendarList, dictSOSitem }) {
  const classes = useStyles()
  const [filters, setFilters] = useState({ selected: actionButtons, queries: filterQueries })
  const [word, setWord] = useState('')

  const setFilter = (filterType) => {
    const alreadyExist = filters.selected.some(item => item.value === filterType.value)
    if (alreadyExist) {
      const deleteMember = filters.selected.filter(item => item.value !== filterType.value)
      const deleteQuery = filters.selected.filter(item => item.query !== filterType.query).map(item => item.query)
      setFilters({ ...filters, selected: deleteMember, queries: deleteQuery })
    } else {
      setFilters({ ...filters, selected: [...filters.selected, filterType], queries: [...filters.queries, filterType.query] })
    }
  }

  const isFilterSelected = (filterType) => {
    const isSelected = filters.selected.some(item => item.value === filterType.value)
    const isDisabled = {
      medicamentos: pillDisabled,
      insumos: labelDisabled,
      equipos: pcDisabled
    }
    const resp = {
      button: isSelected ? filterType.button : 'disabledButton',
      icon: isSelected ? filterType.icon : isDisabled[filterType.value]
    }
    return resp
  }

  const changeTab = (event, newValue) => {
    setTab(newValue)
    setFilters({ selected: actionButtons, queries: filterQueries })
  }

  const setFilterByWord = () => {

    api.get(`api/stock/paciente/${stockData.file.id}/domicilio/?palabra=${word}`).then(response => {
      setStockList({ ...stockList, list: response.data, isFiltering: false })
    })

  }

  const getStock = (id) => {
    const bseUrl = open ? `api/solicitudespedidos/paciente/${stockData.file.id_paciente}` : `api/stock/paciente/${stockData.file.id}/domicilio/`
    api.get(`${bseUrl}?${filters.queries.toString().slice(0, -1).replaceAll(/[,"]+/ig, "")}`).then(response => {
      if (open) {
        const newList = response.data.map(item => {
          return {
            title: `Solicitud Nº ${item.id}`,
            start: moment(item.fecha_despacho).toDate(),
            end: moment(item.fecha_despacho).toDate(),
            id: item.id,
            requestType: item.tipo_solicitud,
            requestState: item.estado_solicitud_insumo,
            pharmacyRequestState: item.estado_solicitud_farmacia,
          }
        })
        setStockCalendarList(newList)
      } else {
        const newList = response.data.map(item => {
          return open ? item : { ...item, isUpdate: false, showMessage: false }
        })
        setStockList({ ...stockList, list: newList })
      }
    })
  }


  useEffect(() => {
    if (stockData.file?.id_paciente) {
      getStock()
    }
  }, [filters.queries])

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='center' mb={3}>
        <TypographyMui variant='caption'>Mostrar:</TypographyMui>
        {actionButtons.map(item => {
          return (
            <Button key={item.value} variant='outlined' className={`ml-3 ${classes[isFilterSelected(item).button]}`} onClick={() => setFilter(item)}>
              <img src={isFilterSelected(item).icon} alt="pill" width='25px' height='25px' className='mr-3' />
              {item.text}
            </Button>
          )
        })}
      </Box>

      <Tabs value={open} onChange={changeTab} indicatorColor="primary" textColor="primary" centered>
        <Tab className={classes.tabs} label="Listado" icon={<ListIcon />} />
        <Tab className={classes.tabs} label="Calendario" icon={<DateRangeIcon />} />
      </Tabs>

      {open === 0 &&
        <Box display='flex' justifyContent='space-between' mt={2}>
          <Box width='35%' display='grid' gridTemplateColumns='1fr 50px'>
            <TextField className={classes.alignSelfCenter} label='Busca por nombre' value={word} onChange={(e) => setWord(e.target.value)} />
            <Fab size="small" color="primary" className={classes.tabs} onClick={setFilterByWord}>
              {stockList.isFiltering ? <CloseIcon /> : <SearchIcon />}
            </Fab>
          </Box>

          <Box display='flex' justifyContent='space-between'mt={2}>
            <Button variant='contained' color='primary' className='px-4 mr-3' onClick={setopenDownloadStock}>
              Descargar Stock
            </Button>
            <Button variant='contained' color='primary' className='px-4' onClick={setIsDialogOpen}>
              <AddIcon className='mr-3' />
              Agregar a Stock
            </Button>
          </Box>
        </Box>
      }
    </>
  )
}

export default HomeStockActions
