import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import TableIncomes from "./TableIncomes";
import TableOutcomes from "./TableOutcomes";
import GraphicComponent from "./Graphic/GraphicComponent";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  table: {
    minWidth: 650
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff"
  }
});

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: "none" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalBalanceHidrico(props) {
  const classes = useStyles();
  const { open, closeModal, dataFile } = props;
  const [startDate, setStartDate] = useState(
    moment()
      .startOf("month")
      .format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf("month")
      .format("YYYY-MM-DD[T]HH:mm:ss")
  );
  const [getFilterOne, setGetFilterOne] = useState([]);
  const [getFilterTwo, setGetFilterTwo] = useState([]);

  const [changes, setChanges] = useState(true);
  const [graphicData, setGraphicData] = useState([]);
  const [activos, setActivos] = useState([]);
  const [totalFilterOne, setTotalFilterOne] = useState(0);
  const [totalFilterTwo, setTotalFilterTwo] = useState(0);
  const totalBalance = totalFilterOne - totalFilterTwo;
  const [changeUpdateDate, setChangeUpdateDate] = useState(false);
  const [balanceTypes, setBalanceTypes] = useState([]);

  const [filters, setFilters] = useState({
    Ingresos: true,
    Egresos: true,
    Balance: false
  });

  const handleDateStartChange = date => {
    setStartDate(date);
    setChangeUpdateDate(true);
  };

  useEffect(() => {
    if (changeUpdateDate) {
      updateFilters(activos);
    }
  });

  const handleDateEndChange = date => {
    setEndDate(date);
    setChangeUpdateDate(true);
  };

  const handleChangeFilters = event => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
    const tempSomething = {
      ...filters,
      [event.target.name]: event.target.checked
    };
    const { Ingresos, Egresos, Balance } = tempSomething;

    const filtersInformationTemp = [
      {
        name: "Ingresos",
        label: "Ingresos",
        value: Ingresos
      },
      {
        name: "Egresos",
        label: "Egresos",
        value: Egresos
      },
      {
        name: "Balance",
        label: "Balance",
        value: Balance
      }
    ];

    const activosTemp = filtersInformationTemp.filter(
      filtered => filtered.value
    );
    setActivos(activosTemp);
    updateFilters(activosTemp);
  };

  const { Ingresos, Egresos, Balance } = filters;

  const countFilters = [Ingresos, Egresos, Balance];

  let checkFilter =
    countFilters.filter(filtered => filtered).length <= 2 &&
    countFilters.filter(filtered => filtered).length === 2;

  useEffect(() => {
    if (changes) {
      initialCharge();
    }
  });

  const initialCharge = () => {
    setChanges(false);
    const firstFilterOne = api.get(
      `api/paciente/${dataFile.id}/balancehidrico/tipo/Ingresos/fecha/${moment(
        startDate
      ).format("YYYY-MM-DD")}T00:00:00/${moment(endDate).format(
        "YYYY-MM-DD"
      )}T23:59:59/`
    );
    const secondFilterTwo = api.get(
      `api/paciente/${dataFile.id}/balancehidrico/tipo/Egresos/fecha/${moment(
        startDate
      ).format("YYYY-MM-DD")}T00:00:00/${moment(endDate).format(
        "YYYY-MM-DD"
      )}T23:59:59/`
    );
    const balanceTypes = api.get(`api/paciente/${dataFile.id}/balancehidrico/tiposmovimientos/`);

    Promise.all([firstFilterOne, secondFilterTwo, balanceTypes])
      .then(response => {
        setBalanceTypes(response[2].data.reduce((accum, obj) => ({...accum, [obj.id]: obj}), {}))
          setGetFilterTwo(response[0].data);
          setGetFilterOne(response[1].data);
          let totalFilterOne = 0;
          let totalFilterTwo = 0;
          let arrayDavid = [];
          response[1].data.map(row => {
            totalFilterOne = totalFilterOne + row.cantidad;
            const tempRow = row;
            arrayDavid.push({
              //name: tempRow.fecha_registro,
              name: moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm"),
              Egresos: tempRow.cantidad,
              Ingreso: 0
            });
          });
          response[0].data.map((row, index) => {
            totalFilterTwo = totalFilterTwo + row.cantidad;
            const tempRow = row;
            if (arrayDavid[index]){
              arrayDavid[index].name = moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm");
              arrayDavid[index].Ingresos = tempRow.cantidad;
            } else {
              arrayDavid.push({
                //name: tempRow.fecha_registro,
                name: moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm"),
                Egreso: 0,
                Ingreso: tempRow.cantidad
              });
            }
          
          });
          setActivos([
            {
              label: "Egresos",
              name: "Egresos",
              value: true
            },
            {
              label: "Ingresos",
              name: "Ingresos",
              value: true
            }
          ]);
          setGraphicData(arrayDavid);
          setTotalFilterOne(totalFilterOne);
          setTotalFilterTwo(totalFilterTwo);

      })

  };

  const updateFilters = activosTemp => {
    setChangeUpdateDate(false);
    if (activosTemp.length === 0) {

      setGetFilterOne([]);
      setGetFilterTwo([]);
      setGraphicData([]);

    }
    if (activosTemp.length === 1) {

      const filterOne = api.get(
        `api/paciente/${dataFile.id}/balancehidrico/tipo/${
          activosTemp[0].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      Promise.all([filterOne])
        .then(response => {
          setGetFilterOne(response[0].data);
          setGetFilterTwo([]);
          let totalFilterOne = 0;
          const arrayDavid = [];
          response[0].data.map(row => {
            totalFilterOne = totalFilterOne + row.cantidad;
            const tempRow = row;
            arrayDavid.push({
              //name: tempRow.fecha_registro,
              name: moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm"),
              [activosTemp[0].name]: tempRow.cantidad
            });
          });
          setGraphicData(arrayDavid);
          setTotalFilterOne(totalFilterOne);

        })

    }
    if (activosTemp.length === 2) {

      const filterOne = api.get(
        `api/paciente/${dataFile.id}/balancehidrico/tipo/${
          activosTemp[0].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      const filterTwo = api.get(
        `api/paciente/${dataFile.id}/balancehidrico/tipo/${
          activosTemp[1].label
        }/fecha/${moment(startDate).format("YYYY-MM-DD[T]HH:mm:ss")}/${moment(
          endDate
        ).format("YYYY-MM-DD[T]HH:mm:ss")}/`
      );
      Promise.all([filterOne, filterTwo])
        .then(response => {
          if (response[0].data.length > response[1].data.length) {
            setGetFilterOne(response[0].data);
            setGetFilterTwo(response[1].data);
            let totalFilterOne = 0;
            let totalFilterTwo = 0;
            let arrayDavid = [];
            response[0].data.map(row => {
              totalFilterOne = totalFilterOne + row.cantidad;
              const tempRow = row;
              arrayDavid.push({
                //name: tempRow.fecha_registro,
                name: moment(tempRow.fecha_registro),
                [activosTemp[0].name]: tempRow.cantidad
              });
            });
            response[1].data.map((row, index) => {
              totalFilterTwo = totalFilterTwo + row.cantidad;
              const tempRow = row;
              //arrayDavid[index].name = tempRow.fecha_registro;
              if (arrayDavid[index]){
                arrayDavid[index].name = moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm");
                arrayDavid[index][activosTemp[1].name] = tempRow.cantidad;
              } else {
                arrayDavid.push({
                  //name: tempRow.fecha_registro,
                  name: moment(tempRow.fecha_registro),
                  [activosTemp[1].name]: tempRow.cantidad
                });
              }
              
            });
            setGraphicData(arrayDavid);
            setTotalFilterOne(totalFilterOne);
            setTotalFilterTwo(totalFilterTwo);
          } else {
            setGetFilterTwo(response[0].data);
            setGetFilterOne(response[1].data);
            let totalFilterOne = 0;
            let totalFilterTwo = 0;
            let arrayDavid = [];
            response[1].data.map(row => {
              totalFilterOne = totalFilterOne + row.cantidad;
              const tempRow = row;
              arrayDavid.push({
                //name: tempRow.fecha_registro,
                name: moment(tempRow.fecha_registro).format("DD-MM-YYYY HH:mm"),
                [activosTemp[1].name]: tempRow.cantidad
              });
            });
            response[0].data.map((row, index) => {
              totalFilterTwo = totalFilterTwo + row.cantidad;
              const tempRow = row;
              //arrayDavid[index].name = tempRow.fecha_registro;
              arrayDavid[index].name = moment(tempRow.fecha_registro).format(
                "DD-MM-YYYY HH:mm"
              );
              arrayDavid[index][activosTemp[0].name] = tempRow.cantidad;
            });
            setGraphicData(arrayDavid);
            setTotalFilterOne(totalFilterOne);
            setTotalFilterTwo(totalFilterTwo);
          }

        })

    }
  };

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      disableBackdropClick
      scroll="paper"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
        align="center"
      >
        Tipo de Ingreso
      </DialogTitle>
      <DialogContent scroll={"paper"}>
        <GraphicComponent
          activos={activos}
          graphicData={graphicData}
          startDate={startDate}
          endDate={endDate}
          handleDateStartChange={handleDateStartChange}
          handleDateEndChange={handleDateEndChange}
          Ingresos={Ingresos}
          Egresos={Egresos}
          Balance={Balance}
          handleChangeFilters={handleChangeFilters}
          checkFilter={checkFilter}
          getFilterOne={getFilterOne}
          getFilterTwo={getFilterTwo}
          totalBalance={totalBalance}
        />
        <Grid container className={classes.root}>
          <Grid item xs={1} />
          <Grid item xs={10} container justify="center" spacing={2}>
            <Grid item xs={6}>
              <TableIncomes
                getFilterOne={getFilterOne}
                totalFilterOne={totalFilterOne}
                balanceTypes={balanceTypes}
              />
            </Grid>
            <Grid item xs={6}>
              <TableOutcomes
                getFilterTwo={getFilterTwo}
                totalFilterTwo={totalFilterTwo}
                balanceTypes={balanceTypes}
              />
            </Grid> 
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "1em" }}>
        <Button
          style={{ textTransform: "none" }}
          variant="contained"
          color="primary"
          onClick={closeModal}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalBalanceHidrico.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  dataFile: PropTypes.object
};
