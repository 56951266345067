import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const TableGenerales = props => {
  const classes = useStyles();
  const { general, setGeneral, setPageRefresh, getSpecialties } = props;
  const [editRow, setEditRow] = useState(-1);

  const setNameSpeciality = id => {
    return getSpecialties
      .filter(specialty => specialty.id === id)
      .map(specialty => specialty.nombre_corto);
  };

  const updateAllRow = (tempRow, event, column) => {
    return tempRow.map((row) => {
      const auxRow = {...row}
      auxRow[column] = event;
      return auxRow
    } )
  }

  const handleStartDateChange = (event, index) => {
    let tempRow = general;
    tempRow[index].startDate = event;
    if (index === 0){
      tempRow = updateAllRow(tempRow, event, "startDate")
    }
    setGeneral(tempRow);
    setPageRefresh(true);
  };

  const handleEndDateChange = (event, index) => {
    let tempRow = general;
    tempRow[index].endDate = event;
    if (index === 0){
      tempRow = updateAllRow(tempRow, event, "endDate")
    }
    setGeneral(tempRow);
    setPageRefresh(true);
  };

  const handleHour = (event, index, indexHora) => {
    let tempRow = general;
    tempRow[index].horas[indexHora] = moment(event).format();
    if (index === 0){
      tempRow = tempRow.map((row) => {
        const auxRow = {...row}
        auxRow.horas[indexHora] = moment(event).format();
        return auxRow
      } )
    }
    setGeneral(tempRow);
    setPageRefresh(true);
  };

  const handleChange = (event, index) => {
    let tempRow = general;
    tempRow[index].select = event;
    setGeneral(tempRow);
    setPageRefresh(true);
  };

  const handleSelect = (event, index) => {
    let tempRow = general;
    tempRow[index].speciality = event.target.value;
    if (index === 0){
      tempRow = updateAllRow(tempRow, event.target.value, "speciality")
    }
    setGeneral(tempRow);
    setPageRefresh(true);
  };

  const startEditing = index => {
    setEditRow(index);
  };

  const stopEditing = () => {
    setEditRow(-1);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Editar</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Item</TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha inicio
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Fecha fin
              </TableCell>
              <TableCell align="center">Especialidad</TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 1
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 2
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 3
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 4
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 5
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 6
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 7
              </TableCell>
              <TableCell align="center" style={{ minWidth: "200px" }}>
                Hora 8
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {general.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    {editRow === index ? (
                      <CheckIcon onClick={() => stopEditing(index)} />
                    ) : (
                      <EditIcon onClick={() => startEditing(index)} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={row.select}
                      onChange={e => {
                        handleChange(e.target.checked, index);
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">{row.item}</TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container item xs={12} justify="space-around">
                          <KeyboardDatePicker
                            fullWidth
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline1"
                            value={row.startDate}
                            onChange={e => handleStartDateChange(e, index)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    ) : (
                      moment(row.startDate).format("DD-MM-YYYY")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline2"
                            value={row.endDate}
                            onChange={e => handleEndDateChange(e, index)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    ) : (
                      moment(row.endDate).format("DD-MM-YYYY")
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editRow === index ? (
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={row.speciality}
                          onChange={e => handleSelect(e, index)}
                        >
                          {getSpecialties.map(specialty => (
                            <MenuItem key={specialty.id} value={specialty.id}>
                              {specialty.nombre_corto}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      setNameSpeciality(row.speciality)
                    )}
                  </TableCell>
                  {row.horas.map((hora, indexHora) => (
                    <TableCell
                      key={indexHora}
                      align="center"
                      style={{ width: "80%" }}
                    >
                      {editRow === index ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justify="space-around">
                            <KeyboardTimePicker
                              margin="normal"
                              id={`time-picker${indexHora}`}
                              value={hora != "" ? hora : null}
                              onChange={e => handleHour(e, index, indexHora)}
                              KeyboardButtonProps={{
                                "aria-label": "change time"
                              }}
                              helperText=""
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      ) : hora === "" ? (
                        hora
                      ) : (
                        moment(hora).format("HH:mm:ss")
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableGenerales;

TableGenerales.propTypes = {
  general: PropTypes.array,
  setGeneral: PropTypes.func,
  setPageRefresh: PropTypes.func,
  getSpecialties: PropTypes.array
};
