import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DateFiler from "./DateFilter";
import Chart from "./Chart";
import TableOrders from "./TableOrders";
import DialogQuickNewOrder from "./Modal/DialogQuickNewOrder";
import api from "utils/API";
import moment from "moment-timezone";
import "moment/locale/es";
import Title from "../../Widgets/OrdeerDashboard/Title";
import Icon from "../../Widgets/OrdeerDashboard/Icon";
import PatientRegistration from "./PatientRegistration"
import DispatchPatientDetail from "./DispatchPatientDetail"
import DispatchPatientSearch from "./DispatchPatientSearch"
import DispatchProgram from "./DispatchProgram"
import { grey, indigo, } from "@material-ui/core/colors";
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import {
  getListSuppliesMedicines,
  getListOrderPatient,
} from "actions/getListAction";
import allActions from "actions";

moment.locale("es");

const OrdersDashboard = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().add(-5, "months").startOf('month'));
  // TODO MODIFY 5 FOR 2
  const [endDate, setEndDate] = useState(moment().add(5, "months").endOf('month'));
  const [ordersPatients, setOrdersPatients] = useState([]);
  const [patientId, setPatientId] = useState(0);
  const [listaPrestaciones, setListaPrestaciones] = useState([]);
  const [listaItemPedidos, setListaItemPedidos] = useState([]);

  const [dispatchDate, setDispatchDate] = useState(null);

  const [firstCharge, setFirstCharge] = useState(true);
  const [updateDate, setUpdateDate] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectOrderPatient, setSelectOrderPatient] = useState(null);

  const [openPatientRegistration, setOpenPatientRegistration] = useState(false);

  const [tempPatientIdInfo, setTempPatientIdInfo] = useState("");
  const [searchName, setSearchName] = useState("");
  const [ordersPatientId, setOrdersPatientId] = useState("");

  const [dataGrafic, setDataGrafic] = useState([]);

  const viewQuotation = useSelector(state => state.currentGlobalVar.patient_package);
  const patient = useSelector(state => state.currentGlobalVar.patientClinicOrder);
  const listPatientScheduledOrders = useSelector(state => state.currentList.listPatientScheduledOrders);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if (firstCharge || updateDate) {
      initialCharge();
    }
    if(listMedical.length === 0 && !chargeMedicine) {
      getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  });

  useEffect(() => {
    getListSuppliesMedicines(dispatch)
  }, []);

  const calculateDate = (row) => {
    if (row.fecha_envio !== "No tiene pedidos programados") return moment(row.fecha_envio).format("YYYY-MM-DD")
    return row.fecha_envio;
  }

  const initialCharge = () => {
    setFirstCharge(false);
    setUpdateDate(false);
    let condition = ''
    if (searchName !== '') {
      condition = `&nombre_paciente=${searchName}`
    }

    const requestOrders = api.get(
      `/api/clinica/pedidostotales/?fecha_inicio=${moment(startDate).format("YYYY-MM-DD")}&&fecha_fin=${moment(endDate).format("YYYY-MM-DD")}${condition}`
    )
    const requestOrderProgram = api.get(
      `/api/clinica/obtener/hoja/registros/?fecha_inicio=${moment(startDate).format("YYYY-MM-DD")}&&fecha_fin=${moment(endDate).format("YYYY-MM-DD")}${condition}`
    )
    Promise.all([requestOrders, requestOrderProgram]).then((request) => {
      setDataGrafic(request[0].data);
      setOrdersPatients(request[1].data.lista_pacientes.reduce((accum, obj) => ([
        ...accum,
        {...obj, fecha_calculada: calculateDate(obj)}
      ]), []));

      let tempChartData = [];
      request[1].data.lista_pacientes.map(row => {
        let tempDate = calculateDate(row);
        if (tempDate){
          if (tempChartData.filter(data => data.date === tempDate).length > 0) {
            const getIndex = tempChartData.findIndex(
              row => row.date === tempDate
            );
            tempChartData[getIndex].count = tempChartData[getIndex].count + 1;
          } else {
            tempChartData.push({
              date: tempDate,
              count: 1
            });
          }
        }
      });
    })
  };

  const getPatientInfo = (id, querystring = '') => {

    dispatch({
      type: "LISTA_PEDIDOS_PROGRAMADOS_PACIENTE",
      payload: null,
    });
    api.get(`/api/clinica/pedidosprogramados/paciente/${id}/ver/${querystring}`).then(response => {
      setOrdersPatientId(response.data.id_paquete_paciente_id)

      let lista_prestaciones = response.data.lista_prestaciones.filter(item => item.id_insumo_medicamento_id != null)
      let lista_item_pedido = response.data.lista_item_pedido.filter(item => item.id_insumo_medicamento_id != null)

      setDispatchDate(lista_prestaciones[0]?.id_solicitud_pedido__fecha_solicitud)
      setListaPrestaciones(lista_prestaciones);
      setListaItemPedidos(lista_item_pedido);
      getListOrderPatient(dispatch, ordersPatientId, response.data.pedidos_programados)

      if (patient.id === response.data?.ficha_paciente?.id){
        const tempPatient = {...patient, ...response.data.ficha_paciente, id_paciente: patient.id_paciente}
        dispatch({ payload: tempPatient, type: 'PACIENTE_ORDEN_CLINICA' })
      }
      dispatch({
        type: "LISTA_PEDIDOS_PROGRAMADOS_PACIENTE",
        payload: response.data.solicitudes_pedidos
      });
      setOpenPatientRegistration(true);
    })

    api.get(
      `/api/clinica/pedidosprogramados/paquetepaciente/paciente/${id}`
    ).then(response => {
      const viewQuotation = {
        forecast: response.data.id_convenio_isapre,
        typePatient: response.data.tipo_paciente,
        typeStay: response.data.tipo_permanencia,

        packagingInfo: {
          id: response.data.id,
          zona: response.data.zona,
          complejidad: response.data.complejidad,
          dia_cama: response.data.dia_cama,
          id_paquete_basado: response.data.id_paquete_basado,
          insumos: response.data.insumos,
          medicamentos: response.data.medicamentos,
          tipo_paciente: response.data.tipo_paciente,
          total_afecto: response.data.total_afecto,
          total_dia: response.data.total_dia,
          total_excento: response.data.total_excento,
          total_mes: response.data.total_mes,
          enviado: response.data.enviado,
        }

      };
      dispatch({ payload: response.data.porcentaje_item, type: 'DESCUENTO_COTIZACION' })
      dispatch({ payload: response.data.tipo_porcentaje, type: 'TIPO_DESCUENTO_COTIZACION' })
      dispatch({ payload: viewQuotation, type: 'PAQUETE_PACIENTE' })
    });

    api.get(
      `/api/descuento/paquete/paciente/?paciente=${id}`
    ).then(response => {
      const auxDiscount = response.data.reduce((accum,obj) => ({
        ...accum,
        [
          obj.id_prestaciones ? `${obj.id_prestaciones}_prestacion_${obj.fuera_paquete}`:
          obj.id_insumo_medicamento ? `${obj.id_insumo_medicamento}_insumos_medicamentos_${obj.fuera_paquete}`:
          obj.id_equipo ? `${obj.id_equipo}_equipo_${obj.fuera_paquete}` : null
        ]: obj
      }), {});
      dispatch({payload: auxDiscount, type: "DICCIONARIO_DESCUENTOS_COTIZACION" });
      dispatch({ payload: auxDiscount, type: 'DICCIONARIO_DESCUENTO_PAQUETE_PACIENTE' });
    });

    api.get(
      `/api/paciente/pedidosprogramados/prestacionespaquete/total/paciente/${id}/`
    ).then(response => {
      const total_value = response.data.valor_solicitudes
      const total_package = response.data.valor_solicitudes
      dispatch({ payload: total_value, type: 'VALOR_CONSUMIDO_MES' })
      dispatch({ payload: total_package, type: 'VALOR_CONSUMIDO_PAQUETE' })
    });
  };


  const getPatientInfoFilter = (value) => {

    let querystring = value?.id ?  `?&solicitud=${value.id}` : ""
    getPatientInfo(patient.id_paciente_id, querystring);
  }

  return (
    <Grid container>
      <Grid item container xs={12} style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
        <Grid item container xs={12} style={{ marginTop: "2rem" }}>
          <Title title="Pedidos programados" variant="h6" ></Title>
        </Grid>
        <Grid item container xs={12} justify="center" style={{ marginTop: "-5px" }}>
          <Button size="medium" style={{ color: indigo[700] }}>FILTROS</Button>
        </Grid>
        <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }}>
          <Grid item container xs={2} alignItems="center">
            <Typography variant="body1" gutterBottom style={{ textTransform: "none", color: grey[900] }}>
              Filtrar por periodo
            </Typography>
          </Grid>
          <Grid item container xs={3}>
            <DateFiler title="Fecha de inicio" date={startDate} setDate={setStartDate} />
            <Divider style={{ boder: "1px solid #E1E4E8" }} />
          </Grid>
          <Grid item container xs={3}>
            <DateFiler title="Fecha de termino" date={endDate} setDate={setEndDate} />
            <Divider style={{ boder: "1px solid #E1E4E8" }} />
          </Grid>
          <Grid item container xs={1} alignItems="center" style={{ paddingTop: "10px" }}>
            <Typography variant="body1" gutterBottom style={{ textTransform: "none", color: grey[900] }}>
              Filtrar por paciente
            </Typography>
          </Grid>
          <Grid item container xs={2} alignItems="center">
            <TextField defaultValue="Paciente N1" value={searchName} helperText="busca por nombre" style={{ margin: "0 0 0 0" }} onChange={(e) => setSearchName(e.target.value)} />
          </Grid>
          <Grid item container xs={1} alignItems="center" justify="center">
            <Icon icon={<SearchIcon />} action={initialCharge} color={grey[50]} />
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Divider style={{ border: "1px solid #303F9F", margin: "10px 0", width: "95%" }} />
        </Grid>
        <Grid item container xs={12} style={{ padding: "0 2% 0 2%" }}>
          <Grid item container xs={6} style={{ height: "95%", marginBottom: "25px" }}>
            <TableOrders ordersPatients={ordersPatients} getPatientInfo={getPatientInfo} setOpenPatientRegistration={setOpenPatientRegistration} setPatientId={setPatientId} setTempPatientIdInfo={setTempPatientIdInfo} />
          </Grid>
          <Grid item container xs={1} justify="center" alignItems="center">
            <Divider style={{ width: "310%", transform: "rotate(90deg)" }} />
          </Grid>
          <Grid item container xs={5}>
            <Chart dataChart={dataGrafic} />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          {openPatientRegistration && <PatientRegistration />}
        </Grid>
        <Grid item container xs={12} justify="center" style={{ paddingTop: "25px" }}>
          <Divider style={{ border: "1px solid #303F9F", margin: "10px 0", width: "95%" }} />
        </Grid>
         <Grid item container xs={12}>
          {openPatientRegistration && <DispatchProgram
            listaPrestaciones={listaPrestaciones}
            ordersPatientId={ordersPatientId}
            dispatchDate={dispatchDate}
            setOpenEditModal={setOpenEditModal}
            getPatientInfo={getPatientInfo}
            setOpenPatientRegistration={setOpenPatientRegistration}
            setSelectOrderPatient={setSelectOrderPatient}
          />}
        </Grid>
        <Grid item container xs={12} justify="center" style={{ paddingTop: "25px" }}>
          <Divider style={{ border: "1px solid #303F9F", margin: "10px 0", width: "95%" }} />
        </Grid>
        <Grid item container xs={12}>
          {openPatientRegistration && <DispatchPatientDetail
            listaPrestaciones={listaItemPedidos}
            getPatientInfoFilter={getPatientInfoFilter}
            dispatchDate={dispatchDate}
          />}
        </Grid>
        <Grid item container xs={12} justify="center" style={{ paddingTop: "25px" }}>
          <Divider style={{ border: "1px solid #303F9F", margin: "10px 0", width: "95%" }} />
        </Grid>
        <Grid item container xs={12}>
          {openPatientRegistration && <DispatchPatientSearch patientId={patientId}/>}
        </Grid>
      </Grid>
      {openEditModal && (
        <DialogQuickNewOrder
          openModal={openEditModal}
          closeModal={() => {
            setOpenEditModal(false);
            setSelectOrderPatient(null);
          }}
          patientId={patientId}
          getPatientInfo={getPatientInfo}
          setOpenPatientRegistration={setOpenPatientRegistration}
          selectOrderPatient={selectOrderPatient}
          listaPrestaciones={listaPrestaciones}
        />
      )}
    </Grid>
  );
};

export default OrdersDashboard;
