import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import {
  Button as ButtonMUI,
  Grid,
  FormControlLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
import DetailText from "views/Widgets/FichaPaciente/detailTextCheck";
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import { red, green, indigo } from "@material-ui/core/colors";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  Add,
  DeleteOutlined,
  Event,
  AccessAlarm,
} from "@material-ui/icons/";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker ,
} from "@material-ui/pickers";
import api from "utils/API";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import ModalMedicalIndications from "./MedicalIndications/ModalMedicalIndications";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import {TypographyMui as Typography} from "utils/componentsStyle";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const useStylesTemplate = makeStyles(styles);

const periodDict = {
  Horario: "hours",
  Diario: "days",
  Semanal: "weeks",
  Mensual: "months",
  Anual: "years",
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles = {
  borderRadius: "2em",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);


export default function ModalNewWork({
  open,
  handleClose,
  listPeriod,
  listassigned,
  array,
  setArray,
  dataFile,
}) {
  const dispatch = useDispatch()
  const classesTemplate = useStylesTemplate();
  const [type, setType] = useState({});
  const [startDate, setstartDate] = useState(
    moment(
      moment()
        .add(2, "hours")
        .format("YYYY-MM-DD HH"),
      "YYYY-MM-DD HH"
    )
  );
  const [endDate, setendDate] = useState(
    moment(
      moment()
        .add(3, "hours")
        .format("YYYY-MM-DD HH"),
      "YYYY-MM-DD HH"
    )
  );
  const [period, setperiod] = useState({});
  const [repeatable, setrepeatable] = useState("");
  const [assignedPosition, setassignedPosition] = useState({});
  const [assignedPeople, setassignedPeople] = useState({});
  const [listPeople, setlistPeople] = useState([]);
  const [description, setdescription] = useState("");
  const [hours, sethours] = useState(1);
  const [validation, setvalidation] = useState(false);
  const [medicine, setmedicine] = useState({});
  const [dose, setdose] = useState(1);
  const [typeAmount, settypeAmount] = useState({});
  const [changeButtons, setchangeButtons] = useState(true);
  const [selectDays, setselectDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [medicalIndications, setMedicalIndications] = useState(false);
  const [listMeasureUnits, setListMeasureUnits] = useState([]);
  const [listSpecificHours, setlistSpecificHours] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const days = [
    {
      label: "L",
      value: 1,
    },
    {
      label: "M",
      value: 2,
    },
    {
      label: "M",
      value: 3,
    },
    {
      label: "J",
      value: 4,
    },
    {
      label: "V",
      value: 5,
    },
    {
      label: "S",
      value: 6,
    },
    {
      label: "D",
      value: 0,
    },
  ];

  const listTypeAmountBenefit = useSelector(state => state.currentList.LISTA_TIPO_CANTIDAD_PRESTACION);
  const listTypeWorkFilePatient = useSelector(state => state.currentList.LISTA_TIPO_TAREAS_FICHA_PACIENTE);
  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listFood = useSelector(state => state.currentList.listFood);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
    validateForm();
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (refresh){
      setrefresh(false)
    }
  });

  const validateForm = () => {
    const arrayValidation = [
      (type.value !== undefined && !["MedHab", "SOS"].includes(type.value)) ||
        (
          ["MedHab", "SOS"].includes(type.value) &&
          medicine.label !== undefined &&
          dose > 0 &&
          typeAmount.value !== undefined
        ),
      assignedPosition.value !== undefined,
      description !== "",
      type.value !== undefined,
      (repeatable === "" && startDate !== "") ||
      (
        repeatable === "frecuencia" &&
        period.value !== undefined &&
        endDate !== "" &&
        startDate !== "" &&
        moment(endDate).diff(moment(startDate), "hour") > 0 &&
        ((period.value === "Horario" && hours > 0) ||
          (period.value === "Semanal" && selectDays.length > 0) ||
          (period.value !== "Semanal" && period.value !== "Horario"))
      ) || (
        repeatable === "personalizado" &&
        endDate !== "" &&
        startDate !== "" &&
        moment(endDate).diff(moment(startDate), "hour") > 0 &&
        listSpecificHours.length > 0 &&
        listSpecificHours.every((item) => item)
      ),
    ];
    if (arrayValidation.every((item) => item === true)) {
      setvalidation(true);
    } else {
      setvalidation(false);
    }
  };

  const handleOnChange = (value, set, typeItem="") => {
    if (["Medicine", "Food", "SOS"].includes(typeItem) && value.value){
      set(value);
    } else if (!["Medicine", "Food", "SOS"].includes(typeItem)){
      set(value);
    }

    if (value.value === "IndicacionesMedicas") {
      setMedicalIndications(true);
    }

    if (["MedHab", "SOS", "Alimentacion"].includes(type.value)){
      console.log(listmeasureunits)
      var listmeasureunits = listTypeAmountBenefit.filter( item =>
        ['ml', 'mg', 'gr', "mcg", "puff"].includes(item.value) );
        setListMeasureUnits(listmeasureunits)
      if(assignedPosition?.label){
        let substring =  assignedPosition.label.toString().substr(0,7);
        if (substring ==='Tecnico' || substring ==='Técnico'){
            setassignedPeople({'value':null,'label':'Sin Asignacion'})
            setlistPeople([])
        }
      }
    }
  };

  const updateValue = () => {
    const auxArray = [];
    if (repeatable === "frecuencia") {
      const tempPeriod = periodDict[period.value];
      if (period.value === "Horario") {
        let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
        const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
        while (
          dateEndIteration.diff(
            moment(dateCondition, "DD-MM-YYYY HH:mm:ss"),
            "minute"
          ) >= 0
        ) {
          auxArray.push({
            tipo_tarea: type.value,
            fecha_hora: moment(dateCondition).format("YYYY-MM-DD HH:mm:ss"),
            id_cargo_asignado: assignedPosition.value,
            id_usuarios: assignedPeople.value,
            descripcion: `${
              dose > 0 &&
              typeAmount.value !== undefined &&
              medicine.value !== undefined &&
              ["MedHab", "SOS", "Alimentacion"].includes(type.value)
                ? `${medicine?.label || ""}, ${dose} ${typeAmount?.label || ""}. `
                : ""
            } ${description}`,
            estado: "Incompleto",
            id_ficha: dataFile.id,
          });
          dateCondition = dateCondition.add(hours, tempPeriod);
        }
      } else if (period.value === "Semanal") {
        let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
        const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
        while (
          dateEndIteration.diff(
            moment(dateCondition, "DD-MM-YYYY HH:mm:ss"),
            "days"
          ) >= 0
        ) {
          if (selectDays.includes(dateCondition.day())) {
            auxArray.push({
              tipo_tarea: type.value,
              fecha_hora: moment(dateCondition).format("YYYY-MM-DD HH:mm:ss"),
              id_cargo_asignado: assignedPosition.value,
              id_usuarios: assignedPeople.value,
              descripcion: `${
                dose > 0 &&
                typeAmount.value !== undefined &&
                medicine.value !== undefined &&
                ["MedHab", "SOS", "Alimentacion"].includes(type.value)
                  ? `${medicine?.label}, ${dose} ${typeAmount?.label}. `
                  : ""
              } ${description}`,
              estado: "Incompleto",
              id_ficha: dataFile.id,
            });
          }
          dateCondition = dateCondition.add(1, "days");
        }
      } else {
        const diff =
          moment(endDate, "DD-MM-YYYY HH:mm:ss").diff(
            moment(startDate, "DD-MM-YYYY HH:mm:ss"),
            tempPeriod
          ) + 1;
        Array.from(Array(diff), (e, i) => {
          auxArray.push({
            tipo_tarea: type.value,
            fecha_hora: moment(startDate, "DD-MM-YYYY HH:mm:ss")
              .add(i, tempPeriod)
              .format("YYYY-MM-DD HH:mm:ss"),
            id_cargo_asignado: assignedPosition.value,
            id_usuarios: assignedPeople.value,
            descripcion: `${
              dose > 0 &&
              typeAmount.value !== undefined &&
              medicine.value !== undefined
                ? `${medicine.label}, ${dose} ${typeAmount.label}. `
                : ""
            } ${description}`,
            estado: "Incompleto",
            id_ficha: dataFile.id,
          });

        });
      }
    } else if (repeatable === "") {
      auxArray.push({
        tipo_tarea: type.value,
        fecha_hora: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        id_cargo_asignado: assignedPosition.value,
        id_usuarios: assignedPeople.value,
        descripcion: `${
          dose > 0 &&
          typeAmount.value !== undefined &&
          medicine.value !== undefined
            ? `${medicine.label}, ${dose} ${typeAmount.label}. `
            : ""
        } ${description}`,
        estado: "Incompleto",
        id_ficha: dataFile.id,
      });
    } else if (repeatable === "personalizado") {
      let dateCondition = moment(startDate, "DD-MM-YYYY HH:mm:ss");
      const dateEndIteration = moment(endDate, "DD-MM-YYYY HH:mm:ss");
      while (
        dateEndIteration.diff(
          moment(dateCondition, "DD-MM-YYYY HH:mm:ss"),
          "days"
        ) >= 0
      ) {
        listSpecificHours.map((row) => {
          const dateHour = `${moment(dateCondition).format("YYYY-MM-DD")} ${moment(row).format("HH:mm:ss")}`
          auxArray.push({
            tipo_tarea: type.value,
            fecha_hora: dateHour,
            id_cargo_asignado: assignedPosition.value,
            id_usuarios: assignedPeople.value,
            descripcion: `${
              dose > 0 &&
              typeAmount.value !== undefined &&
              medicine.value !== undefined &&
              ["MedHab", "SOS", "Alimentacion"].includes(type.value)
                ? `${medicine?.label}, ${dose} ${typeAmount?.label}. `
                : ""
            } ${description}`,
            estado: "Incompleto",
            id_ficha: dataFile.id,
          });
        });
        dateCondition = dateCondition.add(1, "days");
      }
    }
    const tempArray = array;
    const body = {
      tipo_tarea: "TareaPadre",
      fecha_hora: moment().format("YYYY-MM-DD HH:mm:ss"),
      descripcion: `${
        dose > 0 &&
        typeAmount.value !== undefined &&
        medicine.value !== undefined &&
        ["MedHab", "SOS", "Alimentacion"].includes(type.value)
          ? `${medicine?.label}, ${dose} ${typeAmount?.label}. `
          : ""
      } ${description}`,
      estado: "Incompleto",
      id_ficha: dataFile.id,
    }
    api.post(`api/tareaspaciente/`, body).then((result) => {
      const tempResult = result.data;
      const bodyBulk = auxArray.map((row) => {
        const tempRow = row;
        tempRow.id_tarea_padre = tempResult.id;
        return tempRow;
      });
      api.post(`api/paciente/tareas/bulk/?cargar_tareas=1`, { "tareas_paciente": bodyBulk}).then((result) => {
        result.data.map((row) => {
          tempArray.push(row);
        });
        setArray(tempArray);
        handleClose();
      });
    });
    handleClose();
  };

  const handleSelectDay = (button) => {
    let tempArray = selectDays;
    if (selectDays.includes(button)) {
      tempArray = selectDays.filter((row) => row !== button);
    } else {
      tempArray.push(button);
    }
    setselectDays(tempArray);
    setchangeButtons(true);
  };

  const handleRepeatable = (checked) => {
    setperiod({ value: "Horario", label: "Horario" });
    setstartDate(
      moment(
        moment()
          .add(2, "hours")
          .format("YYYY-MM-DD HH"),
        "YYYY-MM-DD HH"
      )
    );
    setendDate(
      moment(
        moment()
          .add(3, "hours")
          .format("YYYY-MM-DD HH"),
        "YYYY-MM-DD HH"
      )
    );
    setrepeatable(checked)
  };

  const handlePeriod = (value) => {
    const temPeriod = value.value;
    const tempDateStart = moment(
      moment()
        .add(2, "hours")
        .format("YYYY-MM-DD HH"),
      "YYYY-MM-DD HH"
    );
    if (temPeriod === "Horario") {
      setstartDate(tempDateStart);
      setendDate(
        moment(
          moment(tempDateStart)
            .add(1, "hours")
            .format("YYYY-MM-DD HH"),
          "YYYY-MM-DD HH"
        )
      );
    } else if (temPeriod === "Diario") {
      setstartDate(tempDateStart);
      setendDate(
        moment(
          moment(tempDateStart)
            .add(1, "days")
            .format("YYYY-MM-DD HH"),
          "YYYY-MM-DD HH"
        )
      );
    } else if (temPeriod === "Semanal") {
      setstartDate(tempDateStart);
      setendDate(
        moment(
          moment(tempDateStart)
            .add(1, "weeks")
            .format("YYYY-MM-DD HH"),
          "YYYY-MM-DD HH"
        )
      );
    } else if (temPeriod === "Mensual") {
      setstartDate(tempDateStart);
      setendDate(
        moment(
          moment(tempDateStart)
            .add(1, "months")
            .format("YYYY-MM-DD HH"),
          "YYYY-MM-DD HH"
        )
      );
    } else if (temPeriod === "Anual") {
      setstartDate(tempDateStart);
      setendDate(
        moment(
          moment(tempDateStart)
            .add(1, "years")
            .format("YYYY-MM-DD HH"),
          "YYYY-MM-DD HH"
        )
      );
    }
    setperiod(value);
  };

  const handleAssignedPosition = (value) => {
    setassignedPeople({});
    setassignedPosition(value);
    let substring =  value.label.toString().substr(0,7);
    if (substring ==='Tecnico' || substring ==='Técnico'){
      if( type.value==='MedHab'){
        setassignedPeople({'value':null,'label':'Sin Asignacion'})
        setlistPeople([])
      }
    }else{
      api.get(`/api/usuario/cargo/${value.value}`).then((response) => {
        setlistPeople(
          response.data.map((row) => {
            return {
              value: row.id,
              label: `(${row.rut}) ${row.first_name} ${row.last_name}`,
            };
          })
        );
      });
    }
  };

  const handleAddHour = () => {
    const tempArray = listSpecificHours
    tempArray.push(null);
    setlistSpecificHours(tempArray);
    setrefresh(true);
  };

  const handleChangeHour = (value, index) => {
    const tempArray = listSpecificHours;
    tempArray[index] = value;
    setlistSpecificHours(tempArray);
    setrefresh(true);
  }

  const handleRemoveHour = (index) => {
    const tempArray = listSpecificHours.filter((row, subIndex) => (index !== subIndex));
    setlistSpecificHours(tempArray);
    setrefresh(true);
  }

  const updateStock = () => {
    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true);
    })
  };

  const _content_ = <div>
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-end"
    item
    xs={12}
  >
    <Grid item xs={4}>
      <DetailSelect
        label="Tipo"
        value={type}
        onchange={(value) => {
          handleOnChange(value, setType);
        }}
        list={listTypeWorkFilePatient}
      />
    </Grid>
    <Grid item xs={4}>
      <DetailText
        label={`Descripción`}
        placeholder={`Descripción`}
        value={description}
        onchange={(e) => {
          handleOnChange(e.target.value, setdescription);
        }}
      />
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <DetailSelect
        label="Cargo asignado "
        value={assignedPosition}
        onchange={(value) => {
          handleAssignedPosition(value);
        }}
        list={listassigned}
        formatGroupLabel={formatGroupLabel}
      />
    </Grid>
    <Grid item xs={4}>
      <DetailSelect
        label="Persona asignada"
        value={assignedPeople}
        onchange={(value) => {
          handleOnChange(value, setassignedPeople);
        }}
        list={listPeople}
        formatGroupLabel={formatGroupLabel}
        validation={false}
      />
    </Grid>
    {["MedHab", "SOS"].includes(type.value) && (
      <Grid item xs={4}>
        <DetailSelect
          label="Medicamento"
          value={medicine}
          onchange={(value) => {
            handleOnChange(value, setmedicine, "Medicine");
          }}
          list={listMedical}
        />
      </Grid>
    )}
    {["MedHab", "SOS"].includes(type.value) && (
      <Grid item xs={4}>
        <DetailNumber
          label={`Dosis`}
          placeholder={`Dosis`}
          value={dose}
          onchange={(e) => {
            handleOnChange(e.target.value, setdose);
          }}
        />
      </Grid>
    )}
    {["MedHab", "SOS"].includes(type.value) && (
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo/Cantidad"
          value={typeAmount}
          onchange={(value) => {
            handleOnChange(value, settypeAmount);
          }}
          list={listMeasureUnits}
        />
      </Grid>
    )}
    {type.value === "Alimentacion" && (
      <Grid item xs={4}>
        <DetailSelect
          label="Alimento"
          value={medicine}
          onchange={(value) => {
            handleOnChange(value, setmedicine, "Food");
          }}
          list={listFood.length > 0 ? listFood : [{value: null, label: "Cargando..."}]}
          validation={false}
        />
      </Grid>
    )}
    {(type.value === "Alimentacion" && medicine?.value ) && (
      <Grid item xs={4}>
        <DetailNumber
          label={`Dosis`}
          placeholder={`Dosis`}
          value={dose}
          onchange={(e) => {
            handleOnChange(e.target.value, setdose);
          }}
          validation={false}
        />
      </Grid>
    )}
    {(type.value === "Alimentacion" && medicine?.value) && (
      <Grid item xs={4}>
        <DetailSelect
          label="Tipo/Cantidad "
          value={typeAmount}
          onchange={(value) => {
            handleOnChange(value, settypeAmount);
          }}
          list={listMeasureUnits}
          validation={false}
        />
      </Grid>
    )}
    {refresh && <div></div>}
    <Grid item xs={12} style={{margin: "34px 0 0 0" }}>
      <Typography variant="h6">
        Repetición
      </Typography>
    </Grid>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      item
      xs={10}
      style={{margin: "9px 0 0 0"}}
    >
      <FormControlLabel
        control={
          <Radio
            checked={repeatable === ""}
            onChange={(event) => setrepeatable(event.target.value)}
            value=""
            name="radio button enabled"
            aria-label="normal"
            icon={
                <RadioButtonUnchecked style={{fill: indigo[500]}} />
            }
            checkedIcon={
                <RadioButtonChecked style={{fill: indigo[500]}}/>
            }
            />
          }
        label="Sin repetición"
      />
      <FormControlLabel
        control={
          <Radio
            checked={repeatable === "frecuencia"}
            onChange={(event) => handleRepeatable(event.target.value)}
            value="frecuencia"
            name="radio button enabled"
            aria-label="extra"
            icon={
                <RadioButtonUnchecked style={{fill: indigo[500]}} />
            }
            checkedIcon={
                <RadioButtonChecked style={{fill: indigo[500]}} />
            }
          />
        }
        label="Por frecuencia"
      />
      <FormControlLabel
        control={
          <Radio
            checked={repeatable === "personalizado"}
            onChange={(event) => setrepeatable(event.target.value)}
            value="personalizado"
            name="radio button enabled"
            aria-label="extra"
            icon={
                <RadioButtonUnchecked style={{fill: indigo[500]}} />
            }
            checkedIcon={
                <RadioButtonChecked style={{fill: indigo[500]}} />
            }
          />
        }
        label="Personalizado"
      />
    </Grid>
    {repeatable === "frecuencia" ?
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
        xs={12}
      >
        <Grid item xs={3}>
          <DetailSelect
            label="Frecuencia"
            value={period}
            onchange={(value) => {
              handlePeriod(value);
            }}
            list={listPeriod}
            menuPlacement="top"
          />
        </Grid>
        <Grid style={{margin: "0 5px"}}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}
        >
          {endDate ?
          <KeyboardDateTimePicker
            label={"Fecha y hora de inicio"}
            variant="inline"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            onChange={setstartDate}
            maxDate={moment(endDate)}
            value={startDate}
            inputVariant={"outlined"}
            InputProps={{
              style: {
                  fontSize: 14,
                  height: "37px",
                  width: "100%"
              }
            }}
            cancelLabel="Cancelar"
            keyboardIcon={<Event/>}
          />:
          <KeyboardDateTimePicker
            label={"Fecha y hora de inicio"}
            variant="inline"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            onChange={setstartDate}
            value={startDate}
            inputVariant={"outlined"}
            cancelLabel="Cancelar"
            keyboardIcon={<Event/>}
          />
          }
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid style={{margin: "0 5px"}}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDateTimePicker
                  label={"Fecha y hora de término"}
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  margin="normal"
                  onChange={setendDate}
                  minDate={startDate ? moment(startDate) : null}
                  value={endDate}
                  inputVariant={"outlined"}
                  InputProps={{
                    style: {
                        fontSize: 14,
                        height: "37px",
                        width: "100%"
                    }
                  }}
                  cancelLabel="Cancelar"
                  keyboardIcon={<Event/>}
              />
          </MuiPickersUtilsProvider>
        </Grid>
        {period.value === "Horario" && (
          <Grid item xs={3}>
            <DetailNumber
              label={`Tiempo de repetición (Horas)`}
              placeholder={`Horas`}
              value={hours}
              onchange={(e) => {
                handleOnChange(e.target.value, sethours);
              }}
            />
          </Grid>
        )}
        {period.value === "Semanal" && (
          <Grid
            item
            xs={5}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            {days.map((row, index) => (
              <Button
                key={`${index}-daybuttons`}
                justIcon
                round
                color={
                  selectDays.includes(row.value) ? "facebook" : "default"
                }
                className={classesTemplate.marginRight}
                onClick={() => handleSelectDay(row.value)}
              >
                {row.label}
              </Button>
            ))}
            {selectDays.length === 0 && (
              <Typography
                variant="caption"
                style={{ color: red[500] }}
                gutterBottom
              >
                A lo menos 1 día seleccionado
              </Typography>
            )}
          </Grid>
        )}
      </Grid> :
    repeatable === "" ?
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        item
        xs={12}
      >
        <Grid style={{margin: "0 5px"}}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDateTimePicker
                label={"Fecha y hora"}
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                onChange={setstartDate}
                value={startDate}
                inputVariant={"outlined"}
                InputProps={{
                  style: {
                      fontSize: 14,
                      height: "37px",
                      width: "100%"
                  }
                }}
                cancelLabel="Cancelar"
                keyboardIcon={<Event/>}
              />
            </MuiPickersUtilsProvider>
        </Grid>
      </Grid> :
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      item
      xs={12}
    >
       <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
        xs={12}
        style={{margin: "0 0 32px 0"}}
      >
        <Grid style={{margin: "0 5px"}}>
          <MuiPickersUtilsProvider  libInstance={moment} utils={MomentUtils}>
            {endDate ?
            <KeyboardDatePicker
              label={"Fecha de inicio"}
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              onChange={setstartDate}
              maxDate={moment(endDate)}
              value={startDate}
              inputVariant={"outlined"}
              InputProps={{
                style: {
                    fontSize: 14,
                    height: "37px",
                    width: "100%"
                }
              }}
              keyboardIcon={<Event/>}
            />:
            <KeyboardDatePicker
              label={"Fecha de inicio"}
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              onChange={setstartDate}
              value={startDate}
              inputVariant={"outlined"}
              keyboardIcon={<Event/>}
            />
            }
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid style={{margin: "0 5px"}}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              label={"Fecha de término"}
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              onChange={setendDate}
              minDate={startDate ? moment(startDate) : null}
              value={endDate}
              inputVariant={"outlined"}
              InputProps={{
                style: {
                  fontSize: 14,
                  height: "37px",
                  width: "100%"
                }
              }}
              cancelLabel="Cancelar"
              keyboardIcon={<Event/>}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
        xs={12}
        style={{margin: "22px 0 0 0"}}
      >
        {listSpecificHours.map((row, index) => (
        <Grid
          key={`index-hour${index}`}
          index={index}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
          style={{margin: "0 0 22px 0"}}
        >
          <Typography variant="body1" style={{margin: "0 16px 0 0"}}>
            Agregar horario
          </Typography>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardTimePicker
              showTodayButton
              todayLabel="Hora actual"
              value={row ? moment(row) : null}
              minutesStep={5}
              onChange={(event) => handleChangeHour(event,index)}
              inputVariant={"outlined"}
              InputProps={{
                style: {
                    fontSize: 14,
                    height: "37px",
                    width: "100%"
                }
              }}
              cancelLabel="Cancelar"
              keyboardIcon={<AccessAlarm/>}
            />
          </MuiPickersUtilsProvider>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => handleRemoveHour(index)}
            style={{backgroundColor: red[500], margin: "0 0 0 20px"}}
          >
            <DeleteOutlined style={{color: red[50]}}/>
          </IconButton>
        </Grid>
        ))}

      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        item
        xs={12}
        style={{margin: "22px 0 0 0"}}
      >
        <Typography variant="body1">
          Agregar horario
        </Typography>
        <IconButton
          size="small"
          aria-label="close"
          onClick={handleAddHour}
          style={{backgroundColor: indigo[500], margin: "0 0 0 20px"}}
        >
          <Add style={{color: indigo[50]}}/>
        </IconButton>
      </Grid>
    </Grid>
    }
  </Grid>
  {medicalIndications && (
    <ModalMedicalIndications
      openModal={medicalIndications}
      closeModal={() => {
        setMedicalIndications(false);
        handleClose();
      }}
      dataFile={dataFile}
      array={array}
      setArray={setArray}
    />
  )}

{["MedHab", "SOS", "Alimentacion"].includes(type.value) && (
<UpdateStockButton
  updateStock={updateStock}
/>
)}
</div>

const _actions_ = <>
 {validation ? (
      <ButtonMUI
        mr={2}
        variant="contained"
        className="text-white"
        style={{ backgroundColor: green[500] }}
        onClick={updateValue}
      >
        Guardar
      </ButtonMUI>
    ) : (
      <ButtonMUI
        disabled
        mr={2}
        variant="contained"
        className="text-white"
      >
        Guardar
      </ButtonMUI>
    )}
    <ButtonMUI
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={handleClose}
    >
      Cerrar
    </ButtonMUI>
  </>


  return (
  <ModalDialog
      open={open}
      onClose={handleClose}
      title={`NUEVA TAREA`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="lg"
  />
  )
}
