import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  Breadcrumbs,
  Link,
  FormControl,
  TextField,
} from "@material-ui/core";
import {ButtonTextMUI} from "utils/componentsStyle";
import LoadInfo from "views/Widgets/FichaPaciente/LoadInfo";
import PersonalRemuneration from "./PersonalRemuneration";
import Variables from "./Variables";
import PersonalRemunerationsTable from "views/Widgets/Remunerations/PersonalRemunerationsTable";
import ModalRemunerationDownload from "components/Remunerations/ModalRemunerationDownload";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import api from "utils/API";
import NavPills from "components/NavPills/NavPills.js";
import { getGlobalVarRemunerations } from "actions/getGlobalAction";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../../styles/Styles.css"

const useStyles = makeStyles(styles);

const Remunerations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setopen] = useState(null);
  const [loading, setloading] = useState(false);
  const [initialCharge, setinitialCharge] = useState(true);
  const [monthDate, setmonthDate] = useState(moment());
  const [search, setsearch] = useState("");
  const [changeButtons, setchangeButtons] = useState(true);
  const [changeFilter, setchangeFilter] = useState(true);
  const [dataArray, setdataArray] = useState([]);
  const [auxDataArray, setauxDataArray] = useState([]);
  const [personalRow,  setpersonalRow] = useState(null);
  const [billingsType, setbillingsType] = useState([]);
  const [statePersonal, setstatePersonal] = useState([]);
  const [valuesData, setvaluesData] = useState([]);
  const [remuneration, setremuneration] = useState(null);
  const [dictChecked, setdictChecked] = useState({});
  const [openModalDownload, setopenModalDownload] = useState(false);
  const [message, setmessage] = useState(null);
  const [selectArea, setselectArea] = useState({});
  const [listAFPTax, setlistAFPTax] = useState([]);
  const [validationAFPTax, setvalidationAFPTax] = useState(false);

  const dictCharge = useSelector(state => state.currentList.dictCharges);

  const dictAreaCharge = Object.values(dictCharge).reduce((acc, item) => {
    if (acc[item["area"]]){
      acc[item["area"]].push(item["id"])
    } else {
      acc[item["area"]] = [item["id"]]
    }
    return {...acc}

  }, {});

  const listAreaWork = Object.keys(dictAreaCharge).map((row) => {return {value: row, label: row}});

  useEffect(() => {
    getGlobalVarRemunerations(moment(monthDate).format("YYYY-MM-DD"),dispatch);
  }, []);


  useEffect(() => {
    if (changeButtons) {
      setchangeButtons(false);
    }
    if (changeFilter) {
      updateFilter();
    }
    if (initialCharge) {
      initialData();
    }
  });

  const initialData = () => {
    setinitialCharge(false);
    api.get(`api/remuneracion/tazacotizacionafp/fecha/${moment(monthDate).format("YYYY-MM-DD")}`).then((response) => {
      const dictData = response.data;
      setlistAFPTax(dictData);
      setvalidationAFPTax(dictData.every((item) => item["tasa_cotizacion_afp"]));
    });
    setchangeFilter(true);

    api.get(`api/fichapersonal/`).then((response) => {
      setdataArray(response.data);
      setauxDataArray(response.data)
      setdictChecked({});

      setchangeButtons(true);
      setloading(true);
    });

  };

  const updateFilter = () => {
    setchangeFilter(false);
    let tempDataArray = dataArray;
    tempDataArray = search ? tempDataArray.filter((row) => (
      `${row.nombres} ${row.apellido_paterno} ${row.apellido_materno}`.toUpperCase().includes(search.toUpperCase()))
    ) : tempDataArray
    tempDataArray = statePersonal.length > 0 ? tempDataArray.filter((row) => (
      statePersonal.includes(row.estado_vigencia))
    ) : tempDataArray
    tempDataArray = billingsType.length > 0 ? tempDataArray.filter((row) => (
      billingsType.includes(row.empresa))
    ) : tempDataArray

    if (selectArea){
      tempDataArray = tempDataArray.filter((row) => (
        dictAreaCharge[selectArea.value].includes(row.id_cargo)
      ))
    }
   

    setauxDataArray(tempDataArray);
    setdictChecked({});
  };

  const handleDate = (value, set) => {

    set(value);
    const dateValue = moment(value).format("YYYY-MM-DD")
    getGlobalVarRemunerations(dateValue, dispatch);
    api.get(`api/remuneracion/tazacotizacionafp/fecha/${dateValue}`).then((response) => {
      const dictData = response.data;
      setlistAFPTax(dictData);
      setvalidationAFPTax(dictData.every((item) => item["tasa_cotizacion_afp"]));

    });
    setchangeFilter(true);
  };

  const handleRemuneration = (row) => {

    api.get(`api/remuneracion/fichapersonal/${row.id}/fecha/${moment(monthDate).format("YYYY-MM-DD")}`).then((response) => {
      setremuneration(response.data["remuneracion"]);
      setvaluesData(response.data["lista_item_remuneracion"].filter((subRow) => (!subRow.valores.eliminado)));
      setpersonalRow(row)

    });
  };

  const handleChange = (checked, row) => {
    const tempDictChecked = dictChecked;
    if (checked){
      tempDictChecked[row.id] = true;
    } else {
      delete tempDictChecked[row.id];
    }
    setdictChecked(tempDictChecked);
    setchangeButtons(true);
  };

  const handleCheckAll = () => {
    let tempDictChecked = {...dictChecked};
    if (Object.keys(tempDictChecked).length > 0){
      tempDictChecked = {};
    } else {
      dataArray.map((row) => {
          tempDictChecked[row.id] = true;
      });
    }
   
    setdictChecked(tempDictChecked);
    setchangeButtons(true);
  };

  const handleBillingsType = (button) => {
    let tempBillingsType = [...billingsType];
    if (billingsType.includes(button)) {
      tempBillingsType = billingsType.filter((row) => row != button);
    } else {
      tempBillingsType.push(button);
    }
    setbillingsType(tempBillingsType);
    setchangeFilter(true);
  }

  const handleState = (button) => {
    let tempStatePersonal = [...statePersonal];
    if (statePersonal.includes(button)) {
      tempStatePersonal = statePersonal.filter((row) => row != button);
    } else {
      tempStatePersonal.push(button);
    }
    setstatePersonal(tempStatePersonal);
    setchangeFilter(true);
  };

  const handleWorkPatient = () => {
    const list_proff = Object.keys(dictChecked).map(value => parseInt(value, 10));
    const list_user = auxDataArray.filter((row) => list_proff.includes(row.id)).map((row) => row.id_usuario)
    const body =  {
      lista_id_usuario: list_user, 
      year: moment(monthDate).format("YYYY"), 
      month: moment(monthDate).format("MM"),
    }

    api.post('api/remuneracion/tareaspacientes/', body, {
      responseType: 'blob'
    }).then((response) => {
      setmessage("Se han obtenido las tareas de los pacientes efectuadas");
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'tareas.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }).catch((error) => {
      console.error("Error al obtener el archivo:", error);
      setmessage("Hubo un error al obtener el archivo.");
    });
  }

  const handleSelectArea = (value) => {
    setselectArea(value);
    setchangeFilter(true);
  }

  return (
    <div>
      {open ? (
        <div></div>
      ) : (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody className="rounded shadow border border-primary">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => {
                      setvaluesData(null);
                      setpersonalRow(null);
                    }}
                    style={{cursor: "pointer"}}
                  >
                    {`Remuneracion`}
                  </Link>
                  {personalRow &&
                  <Link
                    color="inherit"
                    onClick={() => {
                      setvaluesData(null);
                    }}
                    style={{cursor: "pointer"}}
                  >
                    {`Personal: ${personalRow.nombres} ${personalRow.apellido_paterno} ${personalRow.apellido_materno}`}
                  </Link>
                  }

                </Breadcrumbs>
              </CardBody>
            </Card>
          </GridItem>
          {loading ? (
          <GridItem xs={12}>
            <Card>
            {personalRow ?
              <CardBody >
                <PersonalRemuneration
                  personalRow={personalRow}
                  remuneration={remuneration}
                  setremuneration={setremuneration}
                  valuesData={valuesData}
                  changeButtons={changeButtons}

                  setchangeButtons={setchangeButtons}
                  monthDate={monthDate}
                />
              </CardBody> :
              <CardBody>
                <GridItem
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem
                    item
                    xs={2}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <ButtonTextMUI
                      round={true}
                      color={"primary"}
                      variant="contained"
                      className="text-white"
                      size="small"
                      onClick={() => handleDate(moment(monthDate).subtract(1, "months"), setmonthDate)}
                    >
                      Anterior
                    </ButtonTextMUI>
                  </GridItem>
                  <GridItem xs={3}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <DatePicker
                        variant="inline"
                        openTo="year"
                        views={["year", "month"]}
                        margin="normal"
                        label={"Mes remuneración"}
                        placeholder="Mes-Año"
                        onChange={(e) => handleDate(e, setmonthDate)}
                        value={moment(monthDate)}
                        inputVariant={"outlined"}
                      />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                  <GridItem
                    item
                    xs={2}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <ButtonTextMUI
                      round
                      color={"primary"}
                      variant="contained"
                      className="text-white"
                      size="small"
                      onClick={() => handleDate(moment(monthDate).add(1, "months"), setmonthDate)}
                    >
                      Siguiente
                    </ButtonTextMUI>
                  </GridItem>
                </GridItem>
                <GridItem xs={12}>
                  <NavPills
                    color="primary"
                    alignCenter
                    tabs={[
                      {
                        tabButton: "Remuneraciones",
                        tabContent: (
                          <div>
                            <GridItem
                                xs={12}
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{margin: "5px 0 "}}
                              >
                              <GridItem item container xs={6} style={{margin: "5px 0 "}} justify="center">
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="outlined-disabled"
                                      label="Buscar por nombre"
                                      variant="outlined"
                                      value={search}
                                      onChange={e => {
                                        setsearch(e.target.value);
                                        setchangeFilter(true);
                                      }}
                                      style={{width: "100%"}}
                                    />
                                </FormControl>
                              </GridItem>
                              <GridItem item container xs={6} style={{margin: "5px 0 "}} justify="center">
                                 <DetailSelect
                                      value={selectArea}
                                      onchange={(value) => handleSelectArea(value)}
                                      list={listAreaWork}
                                      validation={false}
                                  />
                              </GridItem>
                              <GridItem xs={6}>
                                <div className={classes.cardContentRight}>
                                  <div className={classes.buttonGroup}>
                                    <Button
                                      color={
                                        statePersonal.includes("Activo")
                                          ? "rose"
                                          : "info"
                                      }
                                      className={classes.firstButton}
                                      onClick={() =>
                                        handleState("Activo")
                                      }
                                    >
                                      Activo
                                    </Button>
                                    <Button
                                      color={
                                        statePersonal.includes("Inactivo")
                                          ? "rose"
                                          : "info"
                                      }
                                      className={classes.firstButton}
                                      onClick={() =>
                                        handleState("Inactivo")
                                      }
                                    >
                                      Inactivo
                                    </Button>
                                    <Button
                                      color={
                                        statePersonal.includes("Finiquitado")
                                          ? "rose"
                                          : "info"
                                      }
                                      className={classes.firstButton}
                                      onClick={() =>
                                        handleState("Finiquitado")
                                      }
                                    >
                                      Finiquitado
                                    </Button>
                                  </div>
                                </div>
                              </GridItem>
                              <GridItem xs={3}>
                                <div className={classes.cardContentRight}>
                                  <div className={classes.buttonGroup}>
                                    <Button
                                      color={
                                        billingsType.includes("CMH")
                                          ? "rose"
                                          : "info"
                                      }
                                      className={classes.firstButton}
                                      onClick={() =>
                                        handleBillingsType("CMH")
                                      }
                                    >
                                      CMH
                                    </Button>
                                    <Button
                                      color={
                                        billingsType.includes("MHS")
                                          ? "rose"
                                          : "info"
                                      }
                                      className={classes.firstButton}
                                      onClick={() =>
                                        handleBillingsType("MHS")
                                      }
                                    >
                                      MHS
                                    </Button>
                                  </div>
                                </div>
                              </GridItem>
                              <GridItem xs={3}>
                                <ButtonTextMUI
                                  mr={2}
                                  variant="contained"
                                  className="text-white"
                                  color="primary"
                                  onClick={() => handleCheckAll()}
                                  style={{margin: "0 5px"}}
                                >
                                  Seleccionar todo
                                </ButtonTextMUI>
                              </GridItem>
                            </GridItem>
                            {changeButtons === false && <div></div>}

                            <GridItem xs={12}>
                              <PersonalRemunerationsTable
                                dataArray={auxDataArray}
                                dictChecked={dictChecked}
                                setdata={setdataArray}
                                handleDetail={handleRemuneration}
                                handleChange={handleChange}
                                validationAFPTax={validationAFPTax}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{margin: "10px 0"}}
                            >
                              {(Object.keys(dictChecked).length > 0 &&  validationAFPTax) ?
                              <ButtonTextMUI
                                mr={2}
                                variant="contained"
                                className="text-white"
                                color="primary"
                                onClick={() => setopenModalDownload(true)}
                                style={{margin: "0 5px"}}
                              >
                                Generar remuneraciones
                              </ButtonTextMUI>:
                              <ButtonTextMUI
                                disabled
                                mr={2}
                                variant="contained"
                                style={{margin: "0 5px"}}
                              >
                              {`Generar remuneraciones${validationAFPTax?"":" (Falta tasa cotización AFP)"}`}
                              </ButtonTextMUI>
                              }
                              {Object.keys(dictChecked).length > 0 ?
                              <ButtonTextMUI
                                mr={2}
                                variant="contained"
                                className="text-white"
                                color="primary"
                                onClick={() => handleWorkPatient()}
                                style={{margin: "0 5px"}}
                              >
                                Obtener tareas pacientes efectuadas
                              </ButtonTextMUI>:
                              <ButtonTextMUI
                                disabled
                                mr={2}
                                variant="contained"
                                style={{margin: "0 5px"}}
                              >
                                Obtener tareas pacientes efectuadas
                              </ButtonTextMUI>
                              }
                            </GridItem>

                          </div>
                        )
                      },
                      {
                        tabButton: "Variables",
                        tabContent: (
                          <div>
                            <Variables
                              validationAFPTax={validationAFPTax}
                              setvalidationAFPTax={setvalidationAFPTax}
                              listAFPTax={listAFPTax}
                              setlistAFPTax={setlistAFPTax}
                              monthDate={monthDate}
                              changeButtons={changeButtons}
                              setchangeButtons={setchangeButtons}

                            />
                          </div>
                        )
                      }
                    ]}
                  />
                </GridItem>
              </CardBody>
              }
            </Card>
          </GridItem>

          ) : (
            <LoadInfo title={"Cargando información ..."} />
          )}
          {message && (
            <Snackbar
              place="tc"
              close
              color="success"
              message={message}
              open={!!message}
              closeNotification={() => setmessage(null)}
            />
          )}
        </GridContainer>
      )}
      { openModalDownload &&
        <ModalRemunerationDownload
          open={openModalDownload}
          handleClose={() => {setopenModalDownload(false)}}
          arrayChecked={Object.keys(dictChecked)}
          changeButtons={changeButtons}
          setchangeButtons={setchangeButtons}
          monthDate={monthDate}
        />
      }
    </div>
  );
};

export default Remunerations;
