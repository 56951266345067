import React, { useEffect, useState } from 'react'
import api from 'utils/API';
import "moment/locale/es";

import { makeStyles } from '@material-ui/core/styles'
import { TypographyMui } from 'utils/componentsStyle';
import { Comment } from '@material-ui/icons/';
import { Button, Box, TablePagination, TextField, IconButton } from '@material-ui/core'
import {FilterList} from '@material-ui/icons/';
import usePagination from 'utils/CustomHooks/usePagination';

import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import pill from '../../../../assets/img/icons/stock/medicamentos.svg'
import label from '../../../../assets/img/icons/stock/insumos.svg'
import pc from '../../../../assets/img/icons/stock/equipos.svg'
import folder from '../../../../assets/img/icons/shared/folder.svg'
import amount from '../../../../assets/img/icons/stock/amount.svg'
import copy from '../../../../assets/img/icons/stock/copy.svg'
import ModalDialog from "views/Widgets/Common/ModalDialog";

const useStyles = makeStyles({
  listGrid: {
    display: 'grid',
    gridTemplateColumns: '40% 1fr 30%'
  },
  greenButton: {
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
    color: '#4CAF50',
    border: '1px solid #4CAF50'
  },
  inputSizes: {
    width: 80,
    marginRight: 15
  },
  mr75: {
    marginRight: 75
  },
  '@media (max-width: 800px)': {
    listGrid: {
      display: 'grid',
      gridTemplateColumns: '35% 1fr 40%'
    },
  },
  dropdownOpen: {
    transform: "rotate(-180deg)",
    transition: "all 0.5s ease 0s",
  },
  dropdownClosed: {
    transform: "rotate(0)",
    transition: "all 0.5s ease 0s",
  }
});

const setListItem = (item) => {
  const res = {
    medicamentos: { value: 'Medicamento', icon: pill, color: '#F44336' },
    insumos: { value: 'Insumos', icon: label, color: '#2196F3' },
    equipos: { value: 'Equipos', icon: pc, color: '#9C27B0' }
  }
  return res[item.grupo_item]
}

const HomeStockList = ({ open, stockData, stockList, setStockList, setSpiner, dictSOSitem }) => {
  const classes = useStyles()
  const [pagination, handleChangePage, handleChangeRowsPerPage] = usePagination(5);
  const [orderColumn, setorderColumn] = useState('');
  const [directionColumn, setdirectionColumn] = useState(false)
  const [stockAmount, setStockAmount] = useState('');
  const [openComment, setopenComment] = useState(false);

  const setUpdateStock = (stock) => {
    const setUpdateStock = stockList.list.map(item => item.nombre_item === stock.nombre_item ? { ...item, isUpdate: true } : item)
    setStockList({ list: setUpdateStock, isfiltering: false })
    setStockAmount(stock.total_unidades)
  }

  const updateStock = (stockToEdit) => {

    const stockUpdated = {
      nombre_item: stockToEdit.nombre_item,
      unidades_actualizar: +stockAmount
    }
    api.put(`api/stock/paciente/${stockData.file.id}/domicilio/`, stockUpdated).then(response => {
      const tempList = {...stockList};
      const updatedStockList = stockList.list.map((item) => {
        return item.nombre_item === response.data[0].nombre_item ? { ...response.data[0], isUpdate: false, showMessage: true } : item
      })
      
      tempList.list = updatedStockList;

      setStockList(tempList)
      setStockAmount('')
    })
  };

  const _content_ = <>
    <TypographyMui variant='body2' className='mb-3'>
      {openComment?.nota_prestacion}
    </TypographyMui>
    </>

  const _actions_ = <>
    <Button onClick={() => setopenComment(false)} color="primary" variant="contained">Cerrar</Button>
  </> 

  const handleOrder = (value) => {
    if (value !== orderColumn){
      setdirectionColumn(true)
    } else {
      setdirectionColumn(!directionColumn)
    }
    setorderColumn(value);
  }
  return (
    <Box display={open === 0 ? 'block' : 'none'} px={1.5} mt={1}>
      {/* Header */}
      <Box className={classes.listGrid} mt={3}>
        <Box display='flex' alignItems='center' onClick={() => handleOrder("nombre_item")}>
          <Box mr={0.2}>
            <FilterList
              fontSize='small'
              color='primary'
              className={orderColumn === "nombre_item" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed}
            />
            <img src={folder} alt="folder" width='20px' height='30px' className='mr-3' />
          </Box>
          <TypographyMui variant='subtitle2' color='primary'>NOMBRE</TypographyMui>
        </Box>

        <Box display='flex' alignItems='center' onClick={() => handleOrder("grupo_item")}>
          <Box mr={0.2}>
            <FilterList
              fontSize='small'
              color='primary'
              className={orderColumn === "grupo_item" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed }
            />
            <img src={copy} alt="copy" width='20px' height='30px' className='mr-3' />
          </Box>
          <TypographyMui variant='subtitle2' color='primary'>GRUPO</TypographyMui>
        </Box>

        <Box display='flex' alignItems='center' onClick={() => handleOrder("total_unidades")}>
          <Box mr={0.2}>
            <FilterList
              fontSize='small'
              color='primary'
              className={orderColumn === "total_unidades" && directionColumn ? classes.dropdownOpen : classes.dropdownClosed }
            />
            <img src={amount} alt="amount" width='20px' height='30px' className='mr-3' />
          </Box>
          <TypographyMui variant='subtitle2' color='primary'>STOCK ESTIMADO EN DOMICIILIO</TypographyMui>
        </Box>
      </Box>
      <hr />

      {stockList.list.filter((item) => item.grupo_item)
        .slice(
          pagination.page * pagination.rowsPerPage,
          pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
        ).sort((a, b) => {
          if (orderColumn) {
            if (typeof a[orderColumn] === "string"){
              if (directionColumn){
                return a[orderColumn].toLocaleLowerCase() > b[orderColumn].toLocaleLowerCase() ? 1 : -1
              } else {
                return a[orderColumn].toLocaleLowerCase() < b[orderColumn].toLocaleLowerCase() ? 1 : -1
              }
            } else {
              if (directionColumn){
                return a[orderColumn] > b[orderColumn] ? 1 : -1
              } else {
                return a[orderColumn] < b[orderColumn] ? 1 : -1
              }
            }
          } else return 1
        }).map((item) => {
          return (
            <Box key={item.nombre_item}>
              <Box className={classes.listGrid}>
                <Box alignSelf='center'>
                  <TypographyMui variant='body2'>
                    {`${dictSOSitem[item.id_insumo_medicamento_id] ? "(SOS) " : ""}${item.nombre_item}` }
                  </TypographyMui>
                  {dictSOSitem[item.id_insumo_medicamento_id] &&
                  <IconButton onClick={() => setopenComment(dictSOSitem[item.id_insumo_medicamento_id])} aria-label="delete">
                      <Comment fontSize="inherit" />
                  </IconButton>
                }
                </Box>

                <Box display='flex' alignItems='center'>
                  <img src={setListItem(item).icon} alt={item.grupo_item} width='35px' height='35px' className='mr-3' />
                  <TypographyMui variant='body2' style={{ color: setListItem(item).color }}>{setListItem(item).value}</TypographyMui>
                </Box>

                {item.isUpdate ?
                  <Box display='flex' alignItems='center'>
                    <TextField
                      variant='outlined'
                      size='small'
                      className={classes.inputSizes}
                      onChange={(e) => setStockAmount(e.target.value)}
                      type='number'
                      value={stockAmount}
                    />
                    <Button variant='outlined' color='primary' className={classes.greenButton} onClick={() => updateStock(item)}>
                      <CheckCircleOutlineIcon style={{ color: '#4CAF50' }} className='mr-3' />
                      Guardar
                    </Button>
                  </Box>
                  :
                  <Box>
                    <Box display='flex' alignItems='center'>
                      <TypographyMui className='mr-3' variant='body2'>
                        {item.total_unidades} Unidades
                      </TypographyMui>
                      <Button variant='outlined' color='primary' onClick={() => setUpdateStock(item)} style={{ backgroundColor: 'rgba(63, 81, 181, 0.08)' }}>
                        <UpdateOutlinedIcon color='primary' className='mr-2' />
                        Actualizar
                      </Button>
                    </Box>
                    {item.showMessage &&
                      <TypographyMui className={`text-right d-block ${classes.mr75}`} variant='tooltip'>
                        ✓ stock actualizado correctamente
                      </TypographyMui>
                    }
                  </Box>
                }
              </Box>
              <hr />
            </Box>
          )
        })}

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={stockList.list.length}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        SelectProps={{ inputProps: { "aria-label": "Filas por página" }, native: false }}
        labelRowsPerPage={"Filas por página"}
        nextIconButtonText={"Siguiente"}
        backIconButtonText={"Atrás"}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más que ${to}`}`}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      
      <ModalDialog
        open={!!openComment}
        onClose={() => setopenComment(false)}
        title="Comentario SOS"
        _content_={_content_}
        _actions_ = {_actions_}
        />
    </Box >
  )
}

export default HomeStockList
